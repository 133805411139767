import React, { useState, useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import {
  Grid,
  Link,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Control, UseFormSetValue } from 'react-hook-form';
import { definitions } from '../../api';
import { ApplicationRequestProps } from './Form';
import {
  StyledTextField,
  ControlAutocomplete,
  isMatchArtifactorySaaSHost,
} from './FormHelpers';
import { CreateArtifactoryDialog } from '../CreateArtifactoryDialog';
import { isNil } from 'lodash';
import {
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
} from 'usg-types';
import {
  ARTIFACTORY_PROD_HOST,
  ARTIFACTORY_SAAS_NAME,
  ARTIFACTORY_SAAS_PROD_HOST,
  ARTIFACTORY_SELF_HOSTED_NAME,
} from './Constants';

// Schemas
export type NamespaceRequestArtifactoryRepository =
  definitions['handlers.NamespaceRequestArtifactoryRepository'];
type NamespaceAutocompletionResponseProjectType =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ArtifactoryRegistry = definitions['handlers.ArtifactoryRegistry'];

// props schema
export type ArtifactoryRepositoryProps = {
  stargateProject: NamespaceAutocompletionResponseProjectType;
  defaultArtifactoryRepo: ArtifactoryRegistry[];
  artifactoryRepositoryOptions: ArtifactoryRegistry[];
  autocompletionsLoading: boolean;
  control: Control<ApplicationRequestProps>;
  validationSetter: (isValid: boolean) => void;
  setValue: UseFormSetValue<ApplicationRequestProps>;
  isArtifactorySaaSEnabled: boolean;
  isFormCreation: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    inlineCreate: {
      display: 'flex',
      alignItems: 'center',
    },
    inlineCreateLink: {
      padding: '0 5px 0 0',
      fontWeight: 'bolder',

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export const ArtifactoryRepository = (props: ArtifactoryRepositoryProps) => {
  const {
    stargateProject,
    defaultArtifactoryRepo,
    artifactoryRepositoryOptions,
    autocompletionsLoading,
    control,
    validationSetter,
    setValue,
    isArtifactorySaaSEnabled,
    isFormCreation,
  } = props;
  const classes = useStyles();

  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const [artifactorySaasRepoValue, setArtifactorySaasRepoValue] =
    useState<ArtifactoryRegistry>(defaultArtifactoryRepo[0]);

  const [artifactorySelfHostedRepoValue, setArtifactorySelfHostedRepoValue] =
    useState<ArtifactoryRegistry>(defaultArtifactoryRepo[1]);

  const [artifactorySaasHostValue, setArtifactorySaasHostValue] =
    useState<string>(defaultArtifactoryRepo[0].host);

  const [artifactorySelfHostedHostValue, setArtifactorySelfHostedHostValue] =
    useState<string>(defaultArtifactoryRepo[1].host);

  const [options, setOptions] = useState<
    { key: string; name: string; host: string; dev_tool_id: number }[]
  >(artifactoryRepositoryOptions);

  const [artifactoryDialogOpen, setArtifactoryDialogOpen] =
    useState<boolean>(false);

  const resetArtifactorySaasRepo = () => {
    const empty = {
      key: '',
      name: '',
      host: defaultArtifactoryRepo[0].host,
      dev_tool_id: defaultArtifactoryRepo[0].dev_tool_id,
    };

    setArtifactorySaasRepoValue(empty);
    setValue('artifactory_registries.0', empty);
  };

  const resetArtifactorySelfHostedRepo = () => {
    const empty = {
      key: '',
      name: '',
      host: defaultArtifactoryRepo[1].host,
      dev_tool_id: defaultArtifactoryRepo[1].dev_tool_id,
    };

    setArtifactorySelfHostedRepoValue(empty);
    setValue('artifactory_registries.1', empty);
  };

  const handleDialogClose = (data?: any) => {
    setArtifactoryDialogOpen(false);
    if (isNil(data)) return;

    const { key, name, dev_tool_id } = data;
    const newResourceData = {
      key,
      name,
      dev_tool_id,
      host: artifactorySaasHostValue,
    } as ArtifactoryRegistry;

    setValue('artifactory_registries.0', newResourceData);
    setOptions(options.concat(newResourceData));
    setArtifactorySaasRepoValue(newResourceData);
  };

  const onRepoHostChange = (flavor: string) => {
    return (_: React.ChangeEvent<{}>, v: any) => {
      const isSaaSFlavor = flavor === ARTIFACTORY_SAAS_NAME;

      if (isSaaSFlavor) {
        setArtifactorySaasHostValue(v as string);
        resetArtifactorySaasRepo();
      } else {
        setArtifactorySelfHostedHostValue(v as string);
        resetArtifactorySelfHostedRepo();
      }
    };
  };

  const onArtRepoChange = (flavor: string) => {
    return (_: React.ChangeEvent<{}>, v: any) => {
      const isSaaSFlavor = flavor === ARTIFACTORY_SAAS_NAME;

      if (!v) {
        if (isSaaSFlavor) {
          resetArtifactorySaasRepo();
        } else {
          resetArtifactorySelfHostedRepo();
        }
      } else {
        if (isSaaSFlavor) {
          setArtifactorySaasRepoValue(v);
          setValue('artifactory_registries.0', v);
        } else {
          setArtifactorySelfHostedRepoValue(v);
          setValue('artifactory_registries.1', v);
        }
      }
    };
  };

  return (
    <>
      <Grid container spacing={3} id="mtfuji_wizard_artifactory_registries">
        <Grid item xs={12}>
          <Typography variant="h5">Container Registry</Typography>
          <p>
            Container images built by the CI/CD pipeline need to be stored on a
            container registry so that Kubernetes can pull the image when
            starting containers. Stargate Multicloud uses{' '}
            {
              <Link
                target="_new"
                href={`https://${ARTIFACTORY_SAAS_PROD_HOST}/`}
              >
                Artifactory (SaaS)
              </Link>
            }{' '}
            as the integrated container registry.{' '}
            {isFormCreation ? (
              <p>
                <small>
                  <i>
                    Deprecation Notice: New SMC applications can only use
                    Artifactory (SaaS) as a container registry. If you have a
                    registry on{' '}
                    <Link
                      target="_new"
                      href={`https://${ARTIFACTORY_PROD_HOST}/`}
                    >
                      Artifactory (Self-hosted)
                    </Link>
                    , please consider{' '}
                    <Link
                      target="_new"
                      href="https://portal.tmc-stargate.com/docs/default/Component/STARGATE-WELCOME-GUIDES/non-mandatory-docs/tool-docs/artifactory/manuals/artifactory-cloud-migration/"
                    >
                      migrating
                    </Link>{' '}
                    before creating your SMC application.
                  </i>
                </small>
              </p>
            ) : (
              <p>
                <small>
                  <i>
                    Deprecation Notice:{' '}
                    <Link
                      target="_new"
                      href={`https://${ARTIFACTORY_PROD_HOST}/`}
                    >
                      Artifactory (Self-hosted)
                    </Link>{' '}
                    will soon be deprecated and it is highly encouraged to{' '}
                    <Link
                      target="_new"
                      href="https://portal.tmc-stargate.com/docs/default/Component/STARGATE-WELCOME-GUIDES/non-mandatory-docs/tool-docs/artifactory/manuals/artifactory-cloud-migration/"
                    >
                      migrate
                    </Link>{' '}
                    your resources over to Artifactory (SaaS).
                  </i>
                </small>
              </p>
            )}
          </p>
        </Grid>
        <Grid item xs={4}>
          <ControlAutocomplete
            name="artifactory_registries.0.host"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={[defaultArtifactoryRepo[0].host]}
            getOptionLabel={o => o}
            getOptionSelected={(o, v) => o === v}
            onChange={onRepoHostChange(ARTIFACTORY_SAAS_NAME)}
            value={artifactorySaasHostValue}
            renderInput={params => (
              <StyledTextField {...params} label="Artifactory (SaaS) Host" />
            )}
          />
        </Grid>
        <Grid item xs={7}>
          <ControlAutocomplete
            name="artifactory_registries.0"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={options.filter(o => isMatchArtifactorySaaSHost(o.host))}
            getOptionLabel={o => o.name}
            getOptionSelected={(o, v) => o.key === v.key}
            onChange={onArtRepoChange(ARTIFACTORY_SAAS_NAME)}
            value={artifactorySaasRepoValue}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="Container Registry"
                placeholder="Select a container registry from the list"
                helperText={
                  <div className={classes.inlineCreate}>
                    <Link
                      className={classes.inlineCreateLink}
                      onClick={() => setArtifactoryDialogOpen(true)}
                    >
                      Create a new one
                    </Link>{' '}
                    or select the private container registry to use from the
                    list.
                  </div>
                }
              />
            )}
          />
        </Grid>
        {!isFormCreation && (
          <>
            <Grid item xs={4}>
              <ControlAutocomplete
                name="artifactory_registries.1.host"
                rules={{ required: true }}
                control={control}
                loading={autocompletionsLoading}
                options={[defaultArtifactoryRepo[1].host]}
                getOptionLabel={o => o}
                getOptionSelected={(o, v) => o === v}
                onChange={onRepoHostChange(ARTIFACTORY_SELF_HOSTED_NAME)}
                value={artifactorySelfHostedHostValue}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    label="Artifactory (Self-hosted) Host"
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <ControlAutocomplete
                name="artifactory_registries.1"
                rules={{ required: true }}
                control={control}
                loading={autocompletionsLoading}
                options={options.filter(
                  o => !isMatchArtifactorySaaSHost(o.host),
                )}
                getOptionLabel={o => o.name}
                getOptionSelected={(o, v) => o.key === v.key}
                onChange={onArtRepoChange(ARTIFACTORY_SELF_HOSTED_NAME)}
                value={artifactorySelfHostedRepoValue}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    label="Container Registry"
                    placeholder="Select a container registry from the list"
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <CreateArtifactoryDialog
        open={artifactoryDialogOpen}
        handleDialogClose={handleDialogClose}
        stargateProject={stargateProject}
        devTool={
          isMatchArtifactorySaaSHost(artifactorySaasHostValue)
            ? ARTIFACTORY_SAAS_DEV_TOOL_ID
            : ARTIFACTORY_DEV_TOOL_ID
        }
        defaultValues={{
          repo_name: stargateProject.key.toLowerCase(),
          description: `Default Container registry for ${stargateProject.key}`,
        }}
        isArtifactorySaaSEnabled={isArtifactorySaaSEnabled}
      />
    </>
  );
};
