export const ARTIFACTORY_SAAS_NAME = 'SaaS';

export const ARTIFACTORY_SAAS_PROD_HOST = 'artifactory.stargate.toyota';
export const ARTIFACTORY_SAAS_NON_PROD_HOST =
  'artifactory.test.stargate.toyota';
export const ARTIFACTORY_SELF_HOSTED_NAME = 'SelfHosted';

export const ARTIFACTORY_PROD_HOST = 'artifactory-ha.tmc-stargate.com';
export const ARTIFACTORY_NON_PROD_HOST = 'artifactory-ha.stg.tmc-stargate.com';
export const GITHUB_HOST = 'github.com';
