import React from 'react';
import { Grid, InputLabel, TextField, Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
} from 'usg-types';

interface Props {
  onResourceSubmit: (values: any) => Promise<void>;
  nameDirty: boolean;
  projectData: any;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
}
export const CreateGithubResourceForm = ({
  onResourceSubmit,
  nameDirty,
  projectData,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
}: Props) => {
  return (
    <Grid item xs={6}>
      <Formik
        initialValues={{
          github_organization_name:
            projectData?.response?.data?.key?.toLowerCase(),
          github_organization_description: '',
        }}
        onSubmit={onResourceSubmit}
      >
        {formik => (
          <Form>
            <h3 style={{ color: '#0E4295' }}>Github Organization</h3>
            <p>
              This will create an organization on Stargate's GitHub Enterprise
              instance. Unless you have a specific need to use GitHub
              Enterprise, please consider using the GitHub (EMU) option to
              create an organization on GitHub EMU instead.
            </p>
            <InputLabel
              htmlFor="github_organization_name"
              style={{
                color:
                  (formik.values.github_organization_name?.length <
                    RESOURCE_NAME_MIN_LENGTH.github &&
                    nameDirty) ||
                  (formik.values.github_organization_name?.length >
                    RESOURCE_NAME_MAX_LENGTH.github &&
                    nameDirty)
                    ? 'red'
                    : 'black',
              }}
            >
              Github Organization Name*
            </InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="github_organization_name"
              name="github_organization_name"
              required
              error={
                (formik.values.github_organization_name?.length <
                  RESOURCE_NAME_MIN_LENGTH.github &&
                  nameDirty === true) ||
                (!formik.values.github_organization_name?.match(
                  '^([a-z0-9]+-)*[a-z0-9]+$',
                ) &&
                  nameDirty) ||
                (formik.values.github_organization_name?.length >
                  RESOURCE_NAME_MAX_LENGTH.github &&
                  nameDirty)
              }
              onChange={formik.handleChange}
              value={formik.values.github_organization_name?.toLowerCase()}
              onClick={onResourceNameClick}
              fullWidth
              helperText={`Provide a unique name for your GitHub organization. Only letters, numbers and single hyphens allowed. Must not end with hyphen. No. of Characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.github}, Max=${RESOURCE_NAME_MAX_LENGTH.github}`}
            />

            <br />
            <br />
            <InputLabel htmlFor="github_organization_description">
              Description
            </InputLabel>
            <TextField
              type="text"
              id="github_organization_description"
              name="github_organization_description"
              onChange={formik.handleChange}
              value={formik.values.github_organization_description}
              minRows={4}
              multiline
              fullWidth
              error={
                formik.values.github_organization_description.length >
                  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
              }
              helperText={`Provide a brief description for your GitHub organization. Number of characters allowed Max = ${GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH}`}
              placeholder="Example: GitHub Org for developing One-click delivery automation solutions."
              onClick={onDescriptionClick}
            />
            <br />
            <br />
            <Button
              type="submit"
              id="submit-github"
              disabled={
                formik.values.github_organization_name?.length <
                  RESOURCE_NAME_MIN_LENGTH.github ||
                formik.values.github_organization_name?.length >
                  RESOURCE_NAME_MAX_LENGTH.github ||
                !formik.values.github_organization_name?.match(
                  '^([a-z0-9]+-)*[a-z0-9]+$',
                ) ||
                formik.values.github_organization_description.length >
                  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH ||
                apiLoader
              }
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
