import React, { useState, useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import {
  Grid,
  Link,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Control, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { definitions } from '../../api';
import { StyledTextField, ControlAutocomplete } from './FormHelpers';
import { ApplicationRequestProps } from './Form';
import {
  CreateUserGroupDialog,
  UserGroupInput,
} from '../CreateUserGroupDialog';

// TODO: add frontend validation for groups used in both ro and rw fields

// Schemas
type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationEnvAADGroup = definitions['handlers.AADGroup'];

// props schema
export type UserGroupsProps = {
  stargateProject: NamespaceAutocompletionResponseProject;
  appName: string;
  defaultReadOnlyGroups: ApplicationEnvAADGroup[];
  defaultReadWriteGroups: ApplicationEnvAADGroup[];
  autocompletionsLoading: boolean;
  groupOptions: ApplicationEnvAADGroup[];
  control: Control<ApplicationRequestProps>;
  validationSetter: (isValid: boolean) => void;
  setValue: UseFormSetValue<ApplicationRequestProps>;
  trigger: UseFormTrigger<ApplicationRequestProps>;
};

const useStyles = makeStyles(() =>
  createStyles({
    inlineCreate: {
      display: 'flex',
      alignItems: 'center',
    },
    inlineCreateLink: {
      padding: '0 5px 0 0',
      fontWeight: 'bolder',

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export const getDefaultReadWriteGroupName = (appName: string) => {
  return `${appName}-rw`;
};

export const getDefaultReadOnlyGroupName = (appName: string) => {
  return `${appName}-ro`;
};

export const UserGroups = (props: UserGroupsProps) => {
  const {
    stargateProject,
    appName,
    defaultReadOnlyGroups,
    defaultReadWriteGroups,
    autocompletionsLoading,
    groupOptions,
    control,
    validationSetter,
    setValue,
    trigger,
  } = props;

  const classes = useStyles();

  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const [readOnlyGroupsValue, setreadOnlyGroups] = useState<
    { name: string; uuid: string }[]
  >(defaultReadOnlyGroups);
  const [readwriteGroupsValue, setreadwriteGroups] = useState<
    { name: string; uuid: string }[]
  >(defaultReadWriteGroups);

  const [targetForm, setTargetForm] = React.useState<string>('');
  const [userGroupDialogOpen, setUserGroupDialogOpen] =
    useState<boolean>(false);

  const [defaultValuesMap, setDefaultValuesMap] = React.useState<
    Map<string, UserGroupInput>
  >(new Map());
  useEffect(
    () => {
      setDefaultValuesMap(
        new Map([
          [
            'developer_group',
            {
              // the default app name already prefixes the project key
              // but if users create their own group names
              // the form needs to prefix the project key
              // so for the form's default value we need
              // to remove the prefix before we pass it in
              user_group_name: getDefaultReadWriteGroupName(appName).startsWith(
                `${stargateProject.key.toLowerCase()}-`,
              )
                ? getDefaultReadWriteGroupName(appName).substring(
                    `${stargateProject.key}-`.length,
                  )
                : getDefaultReadWriteGroupName(appName),
              project_description: `Grants members read-write access to ${appName} namespaces`,
            } as UserGroupInput,
          ],
          [
            'readonly_group',
            {
              user_group_name: getDefaultReadOnlyGroupName(appName).startsWith(
                `${stargateProject.key.toLowerCase()}-`,
              )
                ? getDefaultReadOnlyGroupName(appName).substring(
                    `${stargateProject.key}-`.length,
                  )
                : getDefaultReadOnlyGroupName(appName),
              project_description: `Grants members read-only access to ${appName} namespaces`,
            } as UserGroupInput,
          ],
        ]),
      );
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stargateProject],
  );

  useEffect(
    () => {
      setValue('developer_group', readwriteGroupsValue);
      trigger('developer_group');
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readwriteGroupsValue],
  );

  useEffect(
    () => {
      setValue('readonly_group', readOnlyGroupsValue);
      trigger('readonly_group');
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnlyGroupsValue],
  );

  const onReadOnlyGroupsChange = (_: React.ChangeEvent<{}>, v: any) => {
    setreadOnlyGroups(v as ApplicationEnvAADGroup[]);
  };

  const onReadWriteGroupsChange = (_: React.ChangeEvent<{}>, v: any) => {
    setreadwriteGroups(v as ApplicationEnvAADGroup[]);
  };

  const groupsFilter = createFilterOptions({
    stringify: (o: ApplicationEnvAADGroup) => `${o.name}${o.uuid}`,
  });

  const isOptionValuesSet = (optionObj: Object) =>
    Object.values(optionObj).filter((item: string) => item.length === 0)
      .length === 0;

  const handleUserGroupCreate = (selectedTargetForm: string) => {
    setTargetForm(selectedTargetForm);
    setUserGroupDialogOpen(true);
  };

  const handleDialogClose = (
    selectedTargetForm?: string,
    data?: ApplicationEnvAADGroup,
  ) => {
    if (data) {
      switch (selectedTargetForm) {
        case 'readonly_group': {
          const newReadOnlyGroupsValue = [...readOnlyGroupsValue, data];
          setreadOnlyGroups(newReadOnlyGroupsValue);
          break;
        }
        case 'developer_group': {
          const newReadwriteGroupsValue = [...readwriteGroupsValue, data];
          setreadwriteGroups(newReadwriteGroupsValue);
          break;
        }
        default: {
          break;
        }
      }
    }
    setUserGroupDialogOpen(false);
  };

  return (
    <>
      <Grid container spacing={3} id="mtfuji_wizard_github_repository">
        <Grid item xs={12}>
          <Typography variant="h5">Groups</Typography>
          <p>
            At least two user groups are required per application to grant
            access to the namespaces via the Kubernetes API. One group to grant
            members read-only access, and one more to grant read-write access.
          </p>
        </Grid>

        <Grid item xs={12}>
          <ControlAutocomplete
            name="readonly_group"
            rules={{ required: true, validate: isOptionValuesSet }}
            control={control}
            multiple
            loading={autocompletionsLoading}
            options={groupOptions}
            onChange={onReadOnlyGroupsChange}
            value={readOnlyGroupsValue}
            getOptionLabel={o => (o.name || o.uuid ? o.name || o.uuid : '')}
            filterOptions={groupsFilter}
            getOptionSelected={(o, v) => o.uuid === v.uuid}
            renderOption={o => o.name || o.uuid}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="Read-only access"
                placeholder="Select one or more groups from the list"
                helperText={
                  <div className={classes.inlineCreate}>
                    <Link
                      className={classes.inlineCreateLink}
                      onClick={() => handleUserGroupCreate('readonly_group')}
                    >
                      Create a new one
                    </Link>{' '}
                    or select the existing groups to grant read-only access to.
                  </div>
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlAutocomplete
            name="developer_group"
            rules={{ required: true, validate: isOptionValuesSet }}
            control={control}
            multiple
            loading={autocompletionsLoading}
            options={groupOptions}
            onChange={onReadWriteGroupsChange}
            value={readwriteGroupsValue}
            getOptionLabel={o => (o.name || o.uuid ? o.name || o.uuid : '')}
            filterOptions={groupsFilter}
            getOptionSelected={(o, v) => o.uuid === v.uuid}
            renderOption={o => o.name || o.uuid}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="Read-write access"
                placeholder="Select one or more groups from the list"
                helperText={
                  <div className={classes.inlineCreate}>
                    <Link
                      className={classes.inlineCreateLink}
                      onClick={() => handleUserGroupCreate('developer_group')}
                    >
                      Create a new one
                    </Link>{' '}
                    or select the existing groups to grant read-write access to.
                  </div>
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Alert severity="warning">
            Both read-only and read-write group members by default are limited
            to read-only access. But members of the read-write group can append{' '}
            <code>--as {appName}-$env-admin</code> to any <code>kubectl</code>{' '}
            command to gain read-write permissions.
          </Alert>
        </Grid>
      </Grid>
      {['developer_group', 'readonly_group'].map(form => {
        return (
          <CreateUserGroupDialog
            open={userGroupDialogOpen && targetForm === form}
            handleDialogClose={handleDialogClose}
            stargateProject={stargateProject}
            form={form}
            defaultValues={defaultValuesMap}
          />
        );
      })}
    </>
  );
};
