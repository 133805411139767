import React, { useEffect } from 'react';
import { useSearch } from '@backstage/plugin-search-react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  Link,
  Typography,
} from '@material-ui/core';
import { Content } from '../Content';
import { HeaderLink } from './components';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router';
import { useStyles } from './styles';

interface HeaderProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  additionalControls?: JSX.Element;
  extendedContent?: JSX.Element;
  backgroundImg?: string;
  backToLink?: {
    to: string;
    label: string;
  };
}

export const TechDocsPageHeader = ({
  title,
  subtitle,
  additionalControls,
  extendedContent,
  backgroundImg,
  backToLink,
}: HeaderProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    setTerm,
    result: { loading, value: searchVal, error },
  } = useSearch();
  const [searchValue, setSearchValue] = React.useState('');
  const searchResult = searchVal?.results?.slice(0, 10);

  const handleSearch = (event: React.FormEvent) => {
    navigate(`/search?query=${searchValue}&types%5B%5D=techdocs`);
    event.preventDefault();
    setTerm(searchValue);
  };

  useEffect(() => {
    setTerm(searchValue);
  }, [searchValue, setTerm]);

  const backgroundStyle = React.useMemo(
    () =>
      backgroundImg
        ? {
            backgroundImage: `linear-gradient(to left, rgba(145, 185, 250, 1) -14%, rgba(0, 0, 0, 0.76) 78%),url(${backgroundImg})`,
          }
        : {},
    [backgroundImg],
  );
  return (
    <header
      className={`${classes.container} ${classes['container--catalog']}`}
      style={backgroundStyle}
    >
      <Content className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item container direction="column" spacing={3}>
            {backToLink && (
              <Grid item>
                <HeaderLink to={backToLink.to} text={backToLink.label} />
              </Grid>
            )}
            <Grid item>
              <Typography variant="h1">{title}</Typography>
              {subtitle && (
                <Typography className={classes.subtitle} variant="subtitle1">
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.additionalControls}>
            {additionalControls}
          </Grid>
        </Grid>
      </Content>
      <Box mx={5}>
        <Divider light />
      </Box>
      <Content>
        <Grid container direction="column" spacing={6} alignItems="center">
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="h2">Stargate Welcome Guide</Typography>
          </Grid>
          <Grid container item xs={10} lg={8} justifyContent="center">
            <form
              onSubmit={e => {
                handleSearch(e);
              }}
              className={classes.searchFormContainer}
            >
              <Input
                id="techdocs-input-search-field"
                fullWidth
                disableUnderline
                className={classes.searchBar}
                endAdornment={
                  searchValue.length > 0 ? (
                    <Button
                      variant="text"
                      onClick={() => setSearchValue('')}
                      className={classes.searchBarClearButton}
                      id="techdocs-button-clear"
                    >
                      Clear
                    </Button>
                  ) : (
                    <SearchIcon />
                  )
                }
                placeholder="Search"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
              {searchValue.length > 0 && (
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  className={classes.searchResultContainer}
                >
                  {loading && <Typography>Loading...</Typography>}
                  {!loading && error && (
                    <Typography>
                      An error has occurred, please refresh the page and try
                      again.
                    </Typography>
                  )}
                  {!loading && searchResult?.length === 0 && (
                    <Typography>Sorry, no results were found</Typography>
                  )}
                  {!loading &&
                    searchResult?.map(result => (
                      <Grid
                        item
                        xs={12}
                        key={`${result.document.title}${result.document.location}`}
                        className={classes.searchResultItem}
                      >
                        <Link href={result.document.location}>
                          <Typography variant="h4">
                            {result.document.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.searchResultItemText}
                          >
                            {result.document.text.length > 100
                              ? `${result.document.text.slice(0, 100)}...`
                              : result.document.text}
                          </Typography>
                        </Link>
                      </Grid>
                    ))}
                  {searchResult && searchResult.length > 10 && (
                    <Grid item xs={12}>
                      <center>
                        <Link
                          href={`/search?query=${searchValue}&types%5B%5D=techdocs`}
                        >
                          <Typography>View all results</Typography>
                        </Link>
                      </center>
                    </Grid>
                  )}
                </Grid>
              )}
            </form>
          </Grid>
        </Grid>
      </Content>
      {extendedContent && (
        <>
          <Box mx={5}>
            <Divider light />
          </Box>
          <Content className={classes.extendedContent}>
            {extendedContent}
          </Content>
        </>
      )}
    </header>
  );
};
