import React from 'react';
import { Grid, InputLabel, TextField, Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
} from 'usg-types';

import { useStyles } from '../styles';
import { Link } from 'react-router-dom';

interface Props {
  onResourceSubmit: (values: any) => Promise<void>;
  nameDirty: boolean;
  projectData: any;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
}

export const CreateGithubEMUResourceForm = ({
  onResourceSubmit,
  nameDirty,
  projectData,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid item xs={6}>
      <Formik
        initialValues={{
          githubemu_organization_name:
            projectData?.response?.data?.key.toLowerCase(),
          githubemu_organization_description: '',
        }}
        onSubmit={onResourceSubmit}
      >
        {formik => (
          <Form>
            <h3 style={{ color: '#0E4295' }}>GitHub Organization</h3>
            <p>
              This will create a GitHub Organization on Stargate's GitHub EMU
              instance. GitHub EMU stands for “Enterprise Managed Users” and is
              essentially GitHub.com for company-internal use.{' '}
              <Link
                className={classes.hyperLink}
                to="/news?id=EMU-migration-announcement"
                relative="path"
              >
                Learn more about the benefits of GitHub EMU.
              </Link>
            </p>
            <p>
              Please note: to give access to GitHub EMU to users outside of
              Toyota companies, you might need to request for their IP address
              to be added an allowlist.{' '}
              <Link
                className={classes.hyperLink}
                to="/docs/default/Component/STARGATE-WELCOME-GUIDES/vpn"
                relative="path"
              >
                Learn more
              </Link>
              .
            </p>
            <InputLabel
              htmlFor="githubemu_organization_name"
              style={{
                color:
                  (formik.values.githubemu_organization_name?.length <
                    RESOURCE_NAME_MIN_LENGTH.github &&
                    nameDirty) ||
                  (formik.values.githubemu_organization_name?.length >
                    RESOURCE_NAME_MAX_LENGTH.github &&
                    nameDirty)
                    ? 'red'
                    : 'black',
              }}
            >
              GitHub Organization Name*
            </InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="githubemu_organization_name"
              name="githubemu_organization_name"
              required
              error={
                (formik.values.githubemu_organization_name?.length <
                  RESOURCE_NAME_MIN_LENGTH.github &&
                  nameDirty) ||
                (!formik.values.githubemu_organization_name?.match(
                  '^([a-z0-9]+-)*[a-z0-9]+$',
                ) &&
                  nameDirty) ||
                (formik.values.githubemu_organization_name?.length >
                  RESOURCE_NAME_MAX_LENGTH.github &&
                  nameDirty)
              }
              onChange={formik.handleChange}
              value={formik.values.githubemu_organization_name?.toLowerCase()}
              onClick={onResourceNameClick}
              fullWidth
              helperText={`Provide a unique name for your GitHub organization. Only letters, numbers and single hyphens allowed. Must not end with hyphen. No. of Characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.github}, Max=${RESOURCE_NAME_MAX_LENGTH.github}`}
            />

            <br />
            <br />
            <InputLabel htmlFor="githubemu_organization_description">
              Description
            </InputLabel>
            <TextField
              type="text"
              id="githubemu_organization_description"
              name="githubemu_organization_description"
              onChange={formik.handleChange}
              value={formik.values.githubemu_organization_description}
              minRows={4}
              multiline
              fullWidth
              error={
                formik.values.githubemu_organization_description.length >
                  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
              }
              helperText={`Provide a brief description for your GitHub organization to explain what it is for. The description shows up on your organization's profile page. Number of characters allowed Max = ${GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH}`}
              placeholder="Example: GitHub organization for developing vehicle software component."
              onClick={onDescriptionClick}
            />
            <br />
            <br />
            <Button
              type="submit"
              id="submit-githubemu"
              disabled={
                formik.values.githubemu_organization_name?.length <
                  RESOURCE_NAME_MIN_LENGTH.github ||
                formik.values.githubemu_organization_name?.length >
                  RESOURCE_NAME_MAX_LENGTH.github ||
                !formik.values.githubemu_organization_name?.match(
                  '^([a-z0-9]+-)*[a-z0-9]+$',
                ) ||
                formik.values.githubemu_organization_description.length >
                  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH ||
                apiLoader
              }
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
