import React from 'react';
import { Grid, InputLabel, TextField, Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_KEY_MIN_LENGTH,
  RESOURCE_KEY_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
} from 'usg-types';

interface Props {
  onResourceSubmit: (values: any) => Promise<void>;
  keyDirty: boolean;
  projectData: any;
  onResourceKeyClick: () => void;
  nameDirty: boolean;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
}

export const CreateJamaResourceForm = ({
  onResourceSubmit,
  keyDirty,
  projectData,
  onResourceKeyClick,
  nameDirty,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
}: Props) => {
  return (
    <Grid item xs={6}>
      <Formik
        initialValues={{
          jama_resource_key: projectData?.response?.data?.key,
          jama_resource_name: '',
          jama_resource_description: '',
        }}
        onSubmit={onResourceSubmit}
      >
        {formik => (
          <Form>
            <h3 style={{ color: '#0E4295' }}>Jama Project</h3>
            <InputLabel
              htmlFor="jama_resource_key"
              style={{
                color:
                  (formik.values.jama_resource_key.length <
                    RESOURCE_KEY_MIN_LENGTH.jama &&
                    keyDirty === true) ||
                  (formik.values.jama_resource_key.length >
                    RESOURCE_KEY_MAX_LENGTH.jama &&
                    keyDirty === true)
                    ? 'red'
                    : 'black',
              }}
            >
              Jama Project Key*
            </InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="jama_resource_key"
              name="jama_resource_key"
              required
              error={
                (formik.values.jama_resource_key.length <
                  RESOURCE_KEY_MIN_LENGTH.jama &&
                  keyDirty === true) ||
                !formik.values.jama_resource_key.match('^[a-zA-Z0-9]*$') ||
                (formik.values.jama_resource_key.length >
                  RESOURCE_KEY_MAX_LENGTH.jama &&
                  keyDirty === true)
              }
              onChange={formik.handleChange}
              value={formik.values.jama_resource_key}
              onClick={onResourceKeyClick}
              fullWidth
              helperText={`Provide a key  for your Jama Resource. Only letters and numbers allowed. No. of characters allowed - Min=${RESOURCE_KEY_MIN_LENGTH.jama}, Max=${RESOURCE_KEY_MAX_LENGTH.jama}`}
            />

            <br />
            <br />
            <InputLabel
              htmlFor="jama_resource_name"
              style={{
                color:
                  (formik.values.jama_resource_name.length <
                    RESOURCE_NAME_MIN_LENGTH.jama &&
                    nameDirty === true) ||
                  (formik.values.jama_resource_name.length >
                    RESOURCE_NAME_MAX_LENGTH.jama &&
                    nameDirty === true)
                    ? 'red'
                    : 'black',
              }}
            >
              Jama Project Name*
            </InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="jama_resource_name"
              name="jama_resource_name"
              required
              error={
                (formik.values.jama_resource_name.length <
                  RESOURCE_NAME_MIN_LENGTH.jama &&
                  nameDirty === true) ||
                (!formik.values.jama_resource_name.match(
                  SPECIAL_RESOURCE_NAME_REGEX.jama,
                ) &&
                  nameDirty) ||
                (formik.values.jama_resource_name.length >
                  RESOURCE_NAME_MAX_LENGTH.jama &&
                  nameDirty === true)
              }
              onChange={formik.handleChange}
              value={formik.values.jama_resource_name}
              onClick={onResourceNameClick}
              fullWidth
              helperText={`Provide a unique name for your Jama Resource. Only letters, numbers, spaces, periods, underscores, and hyphens are allowed. No. of characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.jama}, Max=${RESOURCE_NAME_MAX_LENGTH.jama}. Spaces and periods at start and end are not allowed.`}
            />

            <br />
            <br />
            <InputLabel htmlFor="jama_project_description">
              Description
            </InputLabel>
            <TextField
              type="text"
              id="jama_resource_description"
              name="jama_resource_description"
              onChange={formik.handleChange}
              value={formik.values.jama_resource_description}
              minRows={4}
              multiline
              fullWidth
              error={
                formik.values.jama_resource_description.length >
                  RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
              }
              helperText={`Provide a brief description of the resource you are creating. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH} `}
              placeholder="Example : The main requirement management software to manage the development process for the One-click delivery automation solutions project."
              onClick={onDescriptionClick}
            />
            <br />
            <br />
            <Button
              type="submit"
              id="submit-jama"
              disabled={
                formik.values.jama_resource_name.length <
                  RESOURCE_NAME_MIN_LENGTH.jama ||
                formik.values.jama_resource_key.length <
                  RESOURCE_KEY_MIN_LENGTH.jama ||
                formik.values.jama_resource_key.length >
                  RESOURCE_KEY_MAX_LENGTH.jama ||
                !formik.values.jama_resource_key.match('^[a-zA-Z0-9]*$') ||
                !formik.values.jama_resource_name.match(
                  SPECIAL_RESOURCE_NAME_REGEX.jama,
                ) ||
                formik.values.jama_resource_name.length >
                  RESOURCE_NAME_MAX_LENGTH.jama ||
                apiLoader ||
                formik.values.jama_resource_description.length >
                  RESOURCE_DESCRIPTION_MAX_LENGTH
              }
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
