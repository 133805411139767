import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    gridArea: 'pageHeader',
  },
  'container--catalog': {
    backgroundColor: '#E0E0E0',
    backgroundSize: 'cover',
    color: '#ffffff',
  },
  content: {
    minHeight: '142px',
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  extendedContent: {
    paddingTop: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(1.5),
  },
  additionalControls: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
  },
  searchBar: {
    borderRadius: '50px',
    border: '1px solid #E0E0E0',
    padding: '5px 20px',
    backgroundColor: 'white',
  },
  searchBarClearButton: {
    padding: '0',
    height: '100%',
  },
  searchFormContainer: {
    position: 'relative',
    width: '100%',
  },
  searchResultContainer: {
    backgroundColor: 'white',
    color: 'black',
    position: 'absolute',
    width: '100%',
    margin: '2px 0',
    borderRadius: '10px',
    border: '1px solid #E0E0E0',
    padding: '10px 10px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  searchResultItem: {
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#E0E0E0',
    },
  },
  searchResultItemText: {
    color: 'black',
  },
}));
