import { Config } from '@backstage/config';
import { NotFoundError } from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';
import { PVMResponse } from './types';

export const pvmApiRef = createApiRef<PvmInterface>({
  id: 'plugin.pvm',
});

interface PvmInterface {
  getDefectsList(projectId: string): Promise<PVMResponse>;
  getProjectDefectsList(projectId: string): Promise<PVMResponse>;
}

interface PvmApiOptions {
  configApi: Config;
  identityApi: IdentityApi;
  gacApi: any;
}

export class PvmApi implements PvmInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private gacApi: any;
  private backendUrl: string;

  constructor(options: PvmApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.gacApi = options.gacApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  public async getDefectsList(customerId: string): Promise<PVMResponse> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const requestUrl = new URL(
      `${this.backendUrl}/api/pvm/defects?customer=${customerId}`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async getProjectDefectsList(projectId: string): Promise<PVMResponse> {
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const gacToken = await this.gacApi.getIdToken();
    const requestUrl = new URL(
      `${this.backendUrl}/api/pvm/defects?project_id=${projectId}`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      {
        gacIdToken: gacToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }
}
