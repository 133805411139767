import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import {
  useController,
  UseControllerProps,
  FieldValues,
} from 'react-hook-form';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { definitions } from '../../api';
import { ARTIFACTORY_SAAS_DEV_TOOL_ID } from 'usg-types';
import {
  ARTIFACTORY_SAAS_NON_PROD_HOST,
  ARTIFACTORY_SAAS_PROD_HOST,
} from './Constants';
import { isEmpty, isNil } from 'lodash';
import { ArtifactoryRegistry, ApplicationRequestProps } from './Form';

// Schemas
type ApplicationGithubRepository = definitions['handlers.GitHubRepository'];

interface ControlFieldProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label: React.ReactNode;
  placeholder: string;
  helperText: React.ReactNode;
  InputProps?: Partial<StandardInputProps>;
}

export const StyledTextField = (props: TextFieldProps) => {
  const { label, placeholder, helperText, ...rest } = props;

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      fullWidth
      variant="outlined"
      margin="dense"
      {...rest}
    />
  );
};

type ControlTextFieldProps<T extends FieldValues> = ControlFieldProps<T> &
  TextFieldProps;

export const ControlTextField = (
  props: ControlTextFieldProps<ApplicationRequestProps>,
) => {
  const { name, control, rules, ...rest } = props;
  const {
    field,
    fieldState: { error },
  } = useController({ name: name, control: control, rules: rules });

  return (
    <StyledTextField
      {...field}
      ref={null}
      error={error ? true : false}
      {...rest}
    />
  );
};

type ControlAutocompleteProps<
  TS extends FieldValues,
  TO,
> = UseControllerProps<TS> &
  AutocompleteProps<TO, boolean, undefined, undefined>;

export const ControlAutocomplete = (
  props: ControlAutocompleteProps<ApplicationRequestProps, any>,
) => {
  const {
    multiple,
    options,
    filterSelectedOptions,
    getOptionLabel,
    filterOptions,
    renderOption,
    getOptionSelected,
    disabled,
    loading,
    renderInput,
    ...rest
  } = props;
  const {
    field: { onChange, onBlur, ref, value },
  } = useController(props);

  return (
    <Autocomplete
      onChange={(_, data) => onChange(data)}
      onBlur={onBlur}
      ref={ref}
      value={value}
      loading={loading}
      disabled={disabled}
      multiple={multiple}
      options={options}
      filterSelectedOptions={filterSelectedOptions}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      renderInput={renderInput}
      {...rest}
    />
  );
};

export const getRepo = (repo: ApplicationGithubRepository) => {
  if (
    !repo ||
    !repo.owner ||
    !repo.name ||
    repo.owner === '' ||
    repo.name === ''
  ) {
    return '';
  }
  return `${repo.owner}/${repo.name}`;
};

export const getRepoHTTPS = (repo: ApplicationGithubRepository) => {
  if (!repo || !repo.host || repo.host === '') {
    return '';
  }
  return `https://${repo.host}/${getRepo(repo)}`;
};

export const getArtifactoryRegistryFQDN = (
  artifactory_repository: ArtifactoryRegistry,
) => {
  if (
    !artifactory_repository ||
    !artifactory_repository.key ||
    artifactory_repository.key === ''
  ) {
    return '';
  }

  if (artifactory_repository.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID) {
    return `${artifactory_repository.host}/${artifactory_repository.key}`;
  }

  return `${artifactory_repository.key}.${artifactory_repository.host}`;
};

export const isMatchArtifactorySaaSHost = (host: string) => {
  const pattern = new RegExp(
    String.raw`(${ARTIFACTORY_SAAS_PROD_HOST}|${ARTIFACTORY_SAAS_NON_PROD_HOST})`,
  );

  return pattern.test(host);
};

export const isEmptyOrNil = (item: any) => {
  return isEmpty(item) || isNil(item);
};
