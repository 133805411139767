import React, { useState } from 'react';
import {
  Grid,
  InputLabel,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  AccordionDetails,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { RESOURCE_NAME_MAX_LENGTH, RESOURCE_NAME_MIN_LENGTH } from 'usg-types';
import { useStyles } from '../styles';

interface Props {
  onSubmitCreateVault: (values: any) => Promise<void>;
  vaultNameDirty: boolean;
  projectData: any;
  onVaultNameClick: () => void;
  enviromentsData: any;
  apiLoader: boolean;
}
export const CreateVaultResourceForm = ({
  onSubmitCreateVault,
  vaultNameDirty,
  projectData,
  onVaultNameClick,
  enviromentsData,
  apiLoader,
}: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([] as any);

  const checkSameGroupValue = (i: any, item: any) => {
    if (
      i?.maintainer_group_id !== undefined ||
      i?.developer_group_id !== undefined
    ) {
      if (i?.maintainer_group_id === i?.developer_group_id) {
        if (i?.id === item.id) {
          return (
            <span>
              <FormHelperText style={{ color: 'red' }}>
                The value for Read only group cannot be the same as
                Administrator group.
              </FormHelperText>
              <br />
              <br />
            </span>
          );
        }
      }
    }
    return <></>;
  };

  return (
    <Grid item xs={6}>
      <Formik
        initialValues={{
          vault_namespace:
            projectData?.response?.data?.key &&
            projectData?.response?.data?.key?.toLowerCase(),
          vaultEnvironments: [] as any,
        }}
        onSubmit={onSubmitCreateVault}
      >
        {formik => (
          <Form>
            <h3 style={{ color: '#0E4295' }}>Vault Namespace</h3>
            <InputLabel htmlFor="vault_namespace">Namespace Name*</InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="vault_namespace"
              name="vault_namespace"
              required
              error={
                (formik.values.vault_namespace.length <
                  RESOURCE_NAME_MIN_LENGTH.vault &&
                  vaultNameDirty) ||
                (formik.values.vault_namespace.length >
                  RESOURCE_NAME_MAX_LENGTH.vault &&
                  vaultNameDirty) ||
                (formik.values.vault_namespace.length > 0 &&
                  !formik.values.vault_namespace.match('^[a-zA-Z0-9]*$'))
              }
              onChange={formik.handleChange}
              value={formik.values.vault_namespace}
              onClick={onVaultNameClick}
              fullWidth
              helperText={`Provide a unique name for your Vault namespace. Only letters and numbers allowed. Min=${RESOURCE_NAME_MIN_LENGTH.vault} - Max=${RESOURCE_NAME_MAX_LENGTH.vault} `}
            />
            <br />
            <br />
            <InputLabel htmlFor="vault_namespace">
              Select Environment*
            </InputLabel>
            <FormHelperText className={classes.enviField}>
              Select Environment(s) to add
            </FormHelperText>
            {enviromentsData?.map((item: any) => (
              <Accordion
                key={item.name}
                expanded={
                  expanded.find((val: any) => val === item.name) === item.name
                }
              >
                <AccordionSummary
                  className={classes.cursor}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id={`additional-actions1-header-${item.key}`}
                >
                  <FormControlLabel
                    aria-label="Acknowledge"
                    label={item?.name}
                    control={
                      <Checkbox
                        onChange={(e: any) => {
                          const selectedResource = {
                            id: item.id,
                            environment: item?.key,
                          };
                          if (e.target.checked) {
                            setExpanded((current: any) => [
                              ...current,
                              item?.name,
                            ]);
                            formik.setFieldValue('vaultEnvironments', [
                              ...formik.values.vaultEnvironments,
                              selectedResource,
                            ]);
                          } else {
                            setExpanded(
                              expanded?.filter((v: any) => v !== item?.name),
                            );
                            formik.setFieldValue(
                              'vaultEnvironments',
                              formik.values.vaultEnvironments.filter(
                                (v: any) => v.id !== item.id,
                              ),
                            );
                          }
                        }}
                      />
                    }
                  />
                </AccordionSummary>
                <AccordionDetails className={classes.accordion}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="maintainer_group_id">
                      Administrator Group*
                    </InputLabel>
                    <Select
                      id={`maintainer_group_id_${item.key}`}
                      key={`${item.name}_maintainer_group_id_${item.key}`}
                      name={`maintainer_group_id_${item.key}`}
                      value={formik.values.vaultEnvironments
                        .filter((x: any) => x.id === item.id)
                        .map((s: any) => s.maintainer_group_id)}
                      input={<OutlinedInput label="Tag" />}
                      displayEmpty
                      onChange={(e: any) => {
                        const selectedResource = {
                          id: item.id,
                          maintainer_group_id: e.target.value,
                          environment: item?.key,
                        };
                        const itemIndex =
                          formik.values.vaultEnvironments.findIndex(
                            (o: any) => o.id === item.id,
                          );
                        if (itemIndex > -1) {
                          const result = formik.values.vaultEnvironments.map(
                            (obj: any) =>
                              obj.id === item.id
                                ? {
                                    id: obj.id,
                                    environment: item?.key,
                                    maintainer_group_id: e.target.value,
                                    developer_group_id: obj.developer_group_id,
                                  }
                                : obj,
                          );
                          formik.setFieldValue('vaultEnvironments', result);
                        } else {
                          formik.setFieldValue('vaultEnvironments', [
                            ...formik.values.vaultEnvironments,
                            selectedResource,
                          ]);
                        }
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {projectData?.response?.data?.user_groups.map(
                        (userGroup: any) => {
                          if (!userGroup.deleted_on)
                            return (
                              <MenuItem
                                id={`menuitem_environment_${item.key}_admingrp_${userGroup.name}`}
                                key={`${item.name}_menuitem_environment_${item.key}_admingrp_${userGroup.name}`}
                                value={userGroup.id}
                              >
                                {userGroup.name}
                              </MenuItem>
                            );
                          return '';
                        },
                      )}
                    </Select>
                    <FormHelperText>Select an admin group</FormHelperText>
                  </FormControl>
                  <br />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="developer_group_id">
                      Read-only Group *
                    </InputLabel>
                    <Select
                      id={`developer_group_id_${item.key}`}
                      key={`${item.name}_developer_group_id_${item.key}`}
                      name={`developer_group_id${item.key}`}
                      value={formik.values.vaultEnvironments
                        .filter((x: any) => x.id === item.id)
                        .map((s: any) => s.developer_group_id)}
                      input={<OutlinedInput label="Tag" />}
                      onChange={(e: any) => {
                        const selectedResource = {
                          id: item.id,
                          developer_group_id: e.target.value,
                          environment: item?.key,
                        };
                        const itemIndex =
                          formik.values.vaultEnvironments.findIndex(
                            (o: any) => o.id === item.id,
                          );
                        if (itemIndex > -1) {
                          const result = formik.values.vaultEnvironments.map(
                            (obj: any) =>
                              obj.id === item.id
                                ? {
                                    id: obj.id,
                                    environment: item?.key,
                                    maintainer_group_id:
                                      obj.maintainer_group_id,
                                    developer_group_id: e.target.value,
                                  }
                                : obj,
                          );
                          formik.setFieldValue('vaultEnvironments', result);
                        } else {
                          formik.setFieldValue('vaultEnvironments', [
                            ...formik.values.vaultEnvironments,
                            selectedResource,
                          ]);
                        }
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {projectData?.response?.data?.user_groups.map(
                        (userGroup: any) => {
                          if (!userGroup.deleted_on)
                            return (
                              <MenuItem
                                id={`menuitem_environment_${item.key}_devgrp_${userGroup.name}`}
                                value={userGroup.id}
                              >
                                {userGroup.name}
                              </MenuItem>
                            );
                          return '';
                        },
                      )}
                    </Select>
                    <FormHelperText>Select a read only group.</FormHelperText>
                  </FormControl>
                  <br />
                  <br />
                  {formik.values.vaultEnvironments.map((i: any) => {
                    return checkSameGroupValue(i, item);
                  })}
                </AccordionDetails>
              </Accordion>
            ))}
            <br />
            <br />
            <Button
              type="submit"
              id="submit-vault"
              disabled={
                formik.values.vault_namespace.length <
                  RESOURCE_NAME_MIN_LENGTH.vault ||
                formik.values.vault_namespace.length >
                  RESOURCE_NAME_MAX_LENGTH.vault ||
                formik.values.vaultEnvironments.length === 0 ||
                formik.values.vaultEnvironments.filter((i: any) =>
                  i.maintainer_group_id === i.developer_group_id ||
                  i.maintainer_group_id === undefined ||
                  i.developer_group_id === undefined
                    ? true
                    : false,
                ).length > 0 ||
                (formik.values.vault_namespace.length > 0 &&
                  !formik.values.vault_namespace.match('^[a-zA-Z0-9]*$')) ||
                apiLoader
              }
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
