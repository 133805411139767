import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'actions',
});

export const catalogItemRouteRef = createRouteRef({
  id: 'catalog-item',
  params: ['catalogKey'],
});
