import React, { useState, useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Link,
  Tooltip,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import SyncTwoToneIcon from '@material-ui/icons/SyncTwoTone';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { definitions } from '../../api';
import { ApplicationRequestProps } from './Form';
import {
  ControlTextField,
  StyledTextField,
  ControlAutocomplete,
} from './FormHelpers';
import { GITHUBEMU_DEV_TOOL_ID, GITHUB_DEV_TOOL_ID } from 'usg-types';
import { CreateGithubOrgDialog } from '../CreateGithubOrgDialog';
import { GithubOrganizationInput } from '../CreateGithubOrgDialog/CreateGithubOrgDialog';

// Schemas
export type NamespaceRequestRepository =
  definitions['handlers.NamespaceRequestRepository'];
export type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationGitHubRepository = definitions['handlers.GitHubRepository'];

// props schema
export type GithubRepositoryProps = {
  stargateProject: NamespaceAutocompletionResponseProject;
  defaultRepo: ApplicationGitHubRepository;
  autocompletionsLoading: boolean;
  repositoryOptions: ApplicationGitHubRepository[];
  control: Control<ApplicationRequestProps>;
  validationSetter: (isValid: boolean) => void;
  setValue: UseFormSetValue<ApplicationRequestProps>;
  getValues: UseFormGetValues<ApplicationRequestProps>;
  trigger: UseFormTrigger<ApplicationRequestProps>;
};

const useStyles = makeStyles(() =>
  createStyles({
    inlineCreateLink: {
      padding: '0',
      fontWeight: 'bolder',

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export const GithubRepository = (props: GithubRepositoryProps) => {
  const {
    stargateProject,
    defaultRepo,
    autocompletionsLoading,
    repositoryOptions,
    control,
    validationSetter,
    setValue,
    getValues,
    trigger,
  } = props;
  const classes = useStyles();

  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const hostOptions = Array.from(
    repositoryOptions.reduce((acc, o) => acc.add(o.host), new Set<string>()),
  );

  const orgOptions = Array.from(
    repositoryOptions.reduce((acc, o) => acc.add(o.owner), new Set<string>()),
  );

  const [repoHostValue, setRepoHostValue] = useState<string>(defaultRepo.host);

  const filterOwners = () => {
    return repositoryOptions
      .filter(o => o.host === repoHostValue)
      .map(o => o.owner);
  };

  const [repoOwnerValue, setRepoOwnerValue] = useState<string>(
    orgOptions.includes(defaultRepo.owner) ? defaultRepo.owner : '',
  );
  const [repoNameValue, setRepoNameValue] = useState<string>(defaultRepo.name);
  const [options, setOptions] = useState<string[]>(orgOptions);
  const [userGithubOrgDialogOpen, setGithubOrgDialogOpen] =
    useState<boolean>(false);

  const handleDialogClose = (data: { key: string; name: string }) => {
    setGithubOrgDialogOpen(false);
    setOptions([...orgOptions, data.name]);
    setRepoOwnerValue(data.name);
  };

  const [defaultValue] = React.useState<GithubOrganizationInput>({
    organization_name: stargateProject.key.toLowerCase(),
    organization_description: `Stargate workspace ${stargateProject.name} repositories`,
  });

  useEffect(
    () => {
      setValue('github_repository', {
        dev_tool_id:
          repoHostValue === 'github.com'
            ? GITHUBEMU_DEV_TOOL_ID
            : GITHUB_DEV_TOOL_ID,
        owner: repoOwnerValue,
        key: repoOwnerValue,
        name: repoNameValue,
        host: repoHostValue,
      } as ApplicationGitHubRepository);
      trigger('github_repository');
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [repoHostValue, repoOwnerValue, repoNameValue],
  );

  const onRepoHostChange = (_: React.ChangeEvent<{}>, v: any) => {
    setRepoHostValue(v as string);
    setRepoOwnerValue('');
  };

  const onRepoOwnerChange = (_: React.ChangeEvent<{}>, v: any) => {
    setRepoOwnerValue(v as string);
  };

  const onRepoNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepoNameValue(event.currentTarget.value);
  };

  const syncRepoName = () => {
    setRepoNameValue(getValues('request.app_name'));
  };

  return (
    <>
      <Grid container spacing={3} id="mtfuji_wizard_github_repository">
        <Grid item xs={12}>
          <Typography variant="h5">GitHub Repository</Typography>
          <p>
            Stargate Multicloud supports repositories on{' '}
            <Link target="_new" href="https://github.tmc-stargate.com/">
              Stargate GitHub Enterprise (GHE)
            </Link>{' '}
            and{' '}
            <Link target="_new" href="https://github.com/enterprises/stargate">
              Stargate GitHub EMU
            </Link>
            . For the best possible integration, we recommend using Stargate
            GitHub EMU (github.com).
          </p>
        </Grid>

        <Grid item xs={3}>
          <ControlAutocomplete
            name="github_repository.host"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={hostOptions}
            getOptionLabel={o => o}
            getOptionSelected={(o, v) => o === v}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="GitHub Host"
                placeholder="Select a GitHub host from the list"
                helperText="Select the GitHub host you want to use."
              />
            )}
            onChange={onRepoHostChange}
            value={repoHostValue}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlAutocomplete
            name="github_repository.owner"
            rules={{ required: true }}
            control={control}
            loading={autocompletionsLoading}
            options={options}
            value={repoOwnerValue}
            getOptionLabel={o => o}
            onChange={onRepoOwnerChange}
            getOptionSelected={(o, v) => o === v}
            filterOptions={filterOwners}
            renderInput={params => (
              <StyledTextField
                {...params}
                label="GitHub Organization"
                placeholder="Select an organization from the list"
                helperText={
                  <div>
                    <Link
                      className={classes.inlineCreateLink}
                      onClick={() => setGithubOrgDialogOpen(true)}
                    >
                      Create a new one
                    </Link>{' '}
                    or Select the organization to create your repository in.
                  </div>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlTextField
            name="github_repository.name"
            rules={{
              required: true,
              pattern: new RegExp('^[A-Za-z0-9_.-]+$'),
            }}
            control={control}
            value={repoNameValue}
            onChange={onRepoNameChange}
            label="Repository Name"
            placeholder="Specify the repository"
            helperText="Specify the repository. The Github Action Runners will be authorized to make changes to your namespaces on MtFuji when run from this repository."
          />
        </Grid>

        <Grid item xs={1}>
          <Tooltip title="Use Application name as Repository name">
            <IconButton style={{ marginTop: '28px' }} onClick={syncRepoName}>
              <SyncTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <CreateGithubOrgDialog
        open={userGithubOrgDialogOpen}
        handleDialogClose={handleDialogClose}
        stargateProjectId={stargateProject.id}
        devTool={
          repoHostValue !== 'github.com'
            ? GITHUB_DEV_TOOL_ID
            : GITHUBEMU_DEV_TOOL_ID
        }
        defaultValues={defaultValue}
      />
    </>
  );
};
