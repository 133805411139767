import { MTFUJI_DEV_TOOL_ID, VAULT_DEV_TOOL_ID } from 'usg-types';
/**
 * checks if devTool supports dynamic groups
 *
 * @param devTool
 * @returns { Boolean }
 */
export const devToolSupportsDynamicGroup = (devTool: any): boolean => {
  return Boolean(devTool?.dynamic_group_supported);
};

/**
 * returns the "required" validation of a role for a resource
 *
 * @param devtool_id
 * @param resource_ug_id
 * @param role
 * @returns { Boolean }
 */

export const isAttachResourcesRoleRequired = (
  devtool_id: any,
  resource_ug_id: any,
  role: any,
): boolean => {
  return (
    ([VAULT_DEV_TOOL_ID].includes(devtool_id) ? role === '' : role !== '') &&
    resource_ug_id !== ''
  );
};

/**
 * checks if role is disabled for a devTool
 *
 * @param idx
 * @returns { Boolean }
 */

export const commonDisableRoleSelection = (idx: string) => {
  return [MTFUJI_DEV_TOOL_ID, VAULT_DEV_TOOL_ID].includes(parseInt(idx, 10));
};

/**
 * checks if resource is assignable by owners
 *
 * @param dev_tool_id
 * @returns { Boolean }
 */

export const commonHideOwnerResources = (dev_tool_id: any) => {
  const devtoolsToHideFromOwners = [MTFUJI_DEV_TOOL_ID, VAULT_DEV_TOOL_ID];
  return devtoolsToHideFromOwners.includes(parseInt(dev_tool_id, 10));
};
