import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  wraperDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 40,
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
    marginRight: 0,
    borderRight: `1px solid ${theme.palette.usgColors?.secondary?.mediumGray}`,
    borderBottom: `1px solid ${theme.palette.usgColors?.secondary?.mediumGray}`,
    background: theme.palette.usgColors?.secondary?.silverGray,
    width: 433,
    '& .textsection': {
      marginTop: 24,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },
    '& .buttonsection': {
      paddingTop: 16,
      paddingBottom: 24,
    },
    '& .iconsection': {
      paddingTop: 24,
      paddingRight: 24,
      paddingLeft: 24,
    },
  },
  divider: {
    marginLeft: 24,
    height: 'inheirt',
    backgroundColor: theme.palette.usgColors?.secondary?.mediumGray,
  },
  footerText: {
    fontSize: '12px',
    width: 306,
  },
  footerTitle: {
    fontSize: '14px',
  },
  feedbackBtn: {
    fontSize: '13px',
    height: '37px',
  },
}));
