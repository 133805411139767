import React from 'react';
import { Link } from '@material-ui/core';

export type Props = {
  text: string;
  language: string | null;
  target?: '_blank' | '_self';
};

export const ContactUsLink = ({ text, target, language }: Props) => {
  const href = `/docs/default/Component/stargate-welcome-guides/${
    language === 'ja' ? `${language}/` : ''
  }contact-us/`;

  return (
    <Link href={href} target={target}>
      {text}
    </Link>
  );
};
