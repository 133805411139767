import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    height: '35px',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0D6AFF',
    background: theme.palette.usgColors?.primary?.white,
    borderRadius: '50%',
  },
}));
