import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  SERVICE_CATALOG_PRODUCTIVITY_DASHBOARD,
  SERVICE_CATALOG_USER_ACTIVITY,
} from 'usg-types';
import { Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  EntityCustomLinksCard,
  EntityCustomLabelsCard,
} from '@internal/plugin-catalog-fork';
import {
  EntityUserProfileCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
// import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';
import {
  EntityPagerDutyCard,
  isPagerDutyAvailable,
} from '@pagerduty/backstage-plugin';
import { StargateInsightsPage } from '@internal/plugin-stargate-insights';
import {
  StatuspageEntityCard,
  isStatuspageAvailable,
} from '@internal/backstage-plugin-statuspage';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ScoreCard } from './ScoreCard';
import {
  isProjectDefectsAvailable,
  EntitySecurityContent,
  isSecurityTabAvailable,
} from '@internal/plugin-pvm';
import { usePermissions } from '@internal/plugin-projects';
import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';

// const cicdContent = (
//   // This is an example of how you can implement your company's logic in entity page.
//   // You can for example enforce that all components of type 'service' should use GitHubActions
//   <EntitySwitch>
//     <EntitySwitch.Case if={isGithubActionsAvailable}>
//       <EntityGithubActionsContent />
//     </EntitySwitch.Case>

//     <EntitySwitch.Case>
//       <EmptyState
//         title="No CI/CD available for this entity"
//         missing="info"
//         description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
//         action={
//           <Button
//             variant="contained"
//             color="primary"
//             href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
//           >
//             Read more
//           </Button>
//         }
//       />
//     </EntitySwitch.Case>
//   </EntitySwitch>
// );

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);
const stargateOverviewContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isStatuspageAvailable}>
        <Grid item md={4} xs={12}>
          <StatuspageEntityCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item md={4} xs={12}>
      <EntityCustomLinksCard
        variant="gridItem"
        title="Source repositories"
        metadataName="sourceRepositories"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLinksCard
        variant="gridItem"
        title="Documentation"
        metadataName="documentationLinks"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLinksCard
        variant="gridItem"
        title="Links"
        metadataName="links"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLinksCard
        variant="gridItem"
        title="Runbook"
        metadataName="runBookLinks"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLinksCard
        variant="gridItem"
        title="Endpoints"
        metadataName="endpoints"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLinksCard
        variant="gridItem"
        title="Observability"
        metadataName="observabilityLinks"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLabelsCard
        variant="gridItem"
        title="Cloud Accounts"
        metadataName="cloudAccounts"
        displayKey="accountId"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLabelsCard
        variant="gridItem"
        title="Slack Channels"
        metadataName="slackChannels"
        displayKey="channelName"
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityCustomLabelsCard
        variant="gridItem"
        title="Versions"
        metadataName="versions"
        displayKey="version"
      />
    </Grid>
  </>
);

const overviewContent = (
  <Grid container spacing={2}>
    {entityWarningContent}
    <EntitySwitch>
      <EntitySwitch.Case if={isComponentType('documentation')}>
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={300} />
        </Grid>
      </EntitySwitch.Case>
      {/* <Grid item md={8} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid> */}
      <EntitySwitch.Case
        if={isComponentType('service') || isComponentType('website')}
      >
        <Grid item md={5}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={5} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={300} />
        </Grid>
        <ScoreCard
          linkInfo={{ title: 'See details', link: './tech-insights' }}
        />
        {stargateOverviewContent}
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/pagerduty"
      title="PagerDuty"
      if={isPagerDutyAvailable}
    >
      <Grid item md={12}>
        <EntityPagerDutyCard readOnly disableChangeEvents />
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/tech-insights" title="Scorecard">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={10}>
          <EntityTechInsightsScorecardCard title="Catalog metadata checks" />
        </Grid>
        <ScoreCard />
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/security"
      title="Security"
      if={isSecurityTabAvailable}
    >
      <EntitySecurityContent />
    </EntityLayout.Route>
    {/* <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={2}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={300} />
        </Grid>
        <Grid item md={12}>
          <EntityProvidingComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={12}>
          <EntityConsumingComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const GroupPage = () => {
  const { entity } = useEntity();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/projects/${entity?.spec?.projectId}`, { replace: true });
  }, [entity?.spec?.projectId, navigate]);

  return <></>;
};

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/security"
      title="Security"
      if={isSecurityTabAvailable}
    >
      <EntitySecurityContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const DomainPage = () => {
  const { entity } = useEntity();

  const projectId: string = entity?.spec?.projectId as string;
  const { isProjectOwner } = usePermissions();
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const [showUserActivity, setShowUserActivity] = React.useState(false);
  const [showProductivityDashboard, setShowProductivityDashboard] =
    React.useState(false);
  // Will remove this once Catalog is in GA
  React.useEffect(
    () => {
      (async () => {
        const response_user_activity = await featureFlagsApi.getBinaryFlag(
          SERVICE_CATALOG_USER_ACTIVITY,
        );
        setShowUserActivity(
          response_user_activity.data && isProjectOwner(Number(projectId)),
        );
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featureFlagsApi, isProjectOwner, projectId],
  );

  React.useEffect(
    () => {
      (async () => {
        const response_user_activity = await featureFlagsApi.getBinaryFlag(
          SERVICE_CATALOG_PRODUCTIVITY_DASHBOARD,
        );
        setShowProductivityDashboard(
          response_user_activity.data && isProjectOwner(Number(projectId)),
        );
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featureFlagsApi, isProjectOwner, projectId],
  );

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {entityWarningContent}
          <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={6}>
            <EntityHasSystemsCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/insights-chargeback" title="Insights">
        <StargateInsightsPage height={2200} dashboardName="PROJECTS_COMMON" />
      </EntityLayout.Route>
      {showUserActivity && (
        <EntityLayout.Route
          path="/insights-useractivity"
          title="User’s activity"
        >
          <StargateInsightsPage
            height={2200}
            dashboardName="PROJECTS_TOOLS_USAGE"
          />
        </EntityLayout.Route>
      )}
      {showProductivityDashboard && (
        <EntityLayout.Route
          path="/insights-productivity-dashboard"
          title="Productivity Dashboard"
        >
          <StargateInsightsPage
            height={4000}
            dashboardName="PROJECTS_PRODUCTIVITY_DASHBOARD"
          />
        </EntityLayout.Route>
      )}
      <EntityLayout.Route
        path="/security"
        title="Security"
        if={isProjectDefectsAvailable}
      >
        <EntitySecurityContent />
      </EntityLayout.Route>
    </EntityLayout>
  );
};

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={<GroupPage />} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={<DomainPage />} />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
