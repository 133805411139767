export enum SortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const URL_CUE = `A valid URL typically starts with 'http://' or 'https://' and contains a domain name, such as 'www.example.com'. Please make sure there are no spaces or special characters (except for dots and forward slashes) in the URL. e.g. https://www.example.com/test`;

export const DEFAULT_TABLE_PAGE_SIZE_OPTIONS: number[] = [20, 50, 100, 1000];

export const EMAIL_REGEX = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/;
export const INVALID_EMAIL_ERROR =
  'There is a problem with one or more of the email addresses you have added. please check for the spelling errors or, if the user is external, you might need to first request a GAC invitation.';
export const EMAIL_VALIDATION_DOCUMENTATION_LINK =
  '/docs/default/component/stargate-welcome-guides/non-mandatory-docs/portal-docs/project-management/user-management/gac/gac-accounts';

export const USER_WARNING_DOMAIN = '@mail.toyota.co.jp';
export const REGEX_END_WITH_ZANY = /.*_z.*/;
