import React, { useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { useForm } from 'react-hook-form';
import { definitions } from '../../api';

type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationEnvAADGroup = definitions['handlers.AADGroup'];

export type UserGroupInput = {
  user_group_name: string;
  project_description: string;
};

export type CreateUserGroupDialogProps = {
  open: boolean;
  handleDialogClose: (
    selectedTargetForm?: string,
    data?: ApplicationEnvAADGroup,
  ) => void;
  stargateProject: NamespaceAutocompletionResponseProject;
  form: string;
  defaultValues: Map<string, UserGroupInput>;
};
export const CreateUserGroupDialog = (props: CreateUserGroupDialogProps) => {
  const { open, handleDialogClose, stargateProject, form, defaultValues } =
    props;
  const [nameDirty, setNameDirty] = useState(false);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset: resetForm,
  } = useForm<UserGroupInput>({
    mode: 'onChange',
    defaultValues: defaultValues.get(form),
  });
  const isValidName = (name: string) => {
    return stargateProject.key.length + name.length <= 31;
  };

  const onSubmit = async (data: UserGroupInput) => {
    const idToken = await authref.getIdToken();
    const userData: any = await projectApi.getUserDetails({ idToken: idToken });
    let fine = true;
    // Create the User Group
    const userGroupResp: any = await projectApi.addProjectUserGroupData(
      {
        idToken: idToken,
        members: [''],
        name: `${stargateProject.key.toLowerCase()}-${data.user_group_name.toLowerCase()}`,
        description: data.project_description,
        accessible_resources: [] as any,
      },
      stargateProject.id,
    );
    if (userGroupResp.response?.status >= 400) {
      const errorMsg = userGroupResp?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      fine = false;
    }
    // Add the user to the newly created User Group
    // TODO: use the V2 API
    const membershipResp: any =
      await projectApi.createUserMembershipRequestsData({
        idToken: idToken,
        user_memberships: [
          {
            user_email: userData.response.data.email,
            user_group_id: Number(userGroupResp.response?.data?.id),
            action: 'ADD',
          },
        ],
        project_id: stargateProject.id,
      });
    if (membershipResp.response?.status >= 400) {
      const errorMsg = membershipResp?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      fine = false;
    }
    if (fine) {
      handleDialogClose(form, userGroupResp.response?.data);
      setNameDirty(false);
      resetForm();
    }
  };

  return (
    <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
      <DialogContent
        id="ug-create-confirmed-dialog"
        style={{ fontWeight: 'bold' }}
      >
        <div style={{ paddingBottom: '24px' }}>
          <h1>Create User Group</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <InputLabel error={!isValid} htmlFor="user_group_name">
                User Group Name*
              </InputLabel>
              <TextField
                style={{ paddingBottom: '0%' }}
                type="text"
                id="user_group_name"
                defaultValue={defaultValues.get(form)?.user_group_name}
                error={!isValid && nameDirty}
                onClick={() => setNameDirty(true)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      style={{
                        textTransform: 'lowercase',
                        marginLeft: '0',
                      }}
                      position="end"
                    >
                      {`${stargateProject.key.toLowerCase()}-`}
                    </InputAdornment>
                  ),
                }}
                helperText={`Provide a unique name for your user group. Only lower case letters, numbers and single-hyphens are allowed. Does not end with hyphen. Max. no. of characters allowed = 32 (including project key - ${`${stargateProject.key.toLowerCase()}-`})`}
                {...register('user_group_name', {
                  required: true,
                  pattern: /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/,
                  validate: isValidName,
                })}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputLabel htmlFor="project_description">Description</InputLabel>
              <TextField
                type="text"
                id="project_description"
                defaultValue={defaultValues.get(form)?.project_description}
                rows={4}
                multiline
                fullWidth
                helperText="Provide a brief description of the user group you are creating. "
                placeholder="Example : To manage users who need developer rights to various software tools and are a part of the TPM project."
                {...register('project_description')}
              />
            </div>
            <div style={{ paddingTop: '5%' }}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Button
                    variant="outlined"
                    type="reset"
                    onClick={() => {
                      handleDialogClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={1}>
                  <Button
                    id="create-ug-submit-button"
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
