import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import {
  questionCreateRouteRef,
  questionPageRouteRef,
  rootRouteRef,
} from './routes';
import { QetaClient, qetaApiRef } from './api/QetaClient';

export const devForumsFrontendPlugin = createPlugin({
  id: 'dev-forums-frontend',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: qetaApiRef,
      deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef },
      factory: ({ fetchApi, discoveryApi }) =>
        new QetaClient({ fetchApi, discoveryApi }),
    }),
  ],
});

export const DevForumsLandingPage = devForumsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DevForumsLandingPage',
    component: () => import('./pages').then(m => m.LandingPage),
    mountPoint: rootRouteRef,
  }),
);

export const DevForumsQuestionPage = devForumsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DevForumsQuestionPage',
    component: () => import('./pages').then(m => m.QuestionPage),
    mountPoint: questionPageRouteRef,
  }),
);

export const DevForumsQuestionFormPage = devForumsFrontendPlugin.provide(
  createRoutableExtension({
    name: 'DevForumsQuestionFormPage',
    component: () => import('./pages').then(m => m.QuestionForm),
    mountPoint: questionCreateRouteRef,
  }),
);
