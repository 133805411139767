import React, { useState } from 'react';
import { Footer, PageLayout } from '@internal/sg-ui-kit';
import {
  identityApiRef,
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { Grid, Typography, Button } from '@material-ui/core';
import { InfoCard, Avatar } from '@backstage/core-components';
import { projectApiRef } from '../../api';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ProfileProjectTable } from './ProfileProjectTable';
import { useAsyncFn } from 'react-use';
import { Progress } from '@backstage/core-components';
import { UserRoleList } from '../UserRoleList';
import { getRoles } from '@internal/sg-utils-common';
import { Role } from 'usg-types';
import { AlertNotification } from '../AlertNotification';
import { useStyles } from './styles';

export function ProfilePageComponent() {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [profileData, setProfileData] = React.useState({} as any);
  const [userData, setUserData] = React.useState({} as any);
  const [token, setToken] = React.useState('');
  const [copyButtonLabel, setCopyButtonLabel] = React.useState('Copy');
  const [roles, setRoles] = React.useState<string[]>([]);
  const [coreDBRoles, setCoreDBRoles] = useState<Role[]>([]);
  const [rolesLoading, setRolesLoading] = React.useState<boolean>(false);

  const handleUserRole = (user: any) => {
    const adminRoles = getRoles(user?.roles);
    setRoles(adminRoles);
  };

  const [{ loading, error }, fetchContent] = useAsyncFn(async () => {
    const profile = await identityApi.getProfileInfo();
    setProfileData(profile);

    const idToken = await authref.getIdToken();
    const params = {
      idToken: idToken,
    };
    const queryParams = {
      include: 'all',
    };
    setToken(idToken);

    try {
      const data: any = await projectApi.getUserDetails(params, queryParams);
      const resData = data?.response?.data;
      setUserData(resData);
      handleUserRole(resData);
    } catch (err) {
      const pError = err?.message;
      errorApi.post(new Error(`${pError}`));
    }
  }, []);

  const fetchDBRoles = async () => {
    try {
      setRolesLoading(true);
      const idToken = await authref.getIdToken();
      setToken(idToken);
      const res: any = await projectApi.getUserRoles(idToken);
      const nonDeletedRoles = res?.data?.roles.filter(
        (role: Role) => !role?.deleted_on,
      );
      setCoreDBRoles(nonDeletedRoles);
      setRolesLoading(false);
    } catch (err: any) {
      errorApi.post(new Error(err?.message));
      setRolesLoading(false);
    }
  };

  const process = async () => {
    await fetchDBRoles();
    await fetchContent();
  };
  React.useEffect(() => {
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || rolesLoading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  return (
    <PageLayout
      title={`Welcome, ${profileData?.displayName}`}
      headerBackgroundImg="assets/welcome_bg.webp"
      subtitle="Your Stargate profile"
    >
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InfoCard variant="gridItem" className={classes.profileCard}>
              <Grid item xs={12} md={12} lg={3}>
                <Avatar
                  picture={profileData?.picture}
                  // customStyles={avatarStyles}
                  displayName={profileData?.displayName}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={9}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.displayName}
                  noWrap
                >
                  {profileData?.displayName}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.email}
                  noWrap
                >
                  {profileData?.email}
                </Typography>
                {userData?.roles && (
                  <UserRoleList roleList={coreDBRoles} maxRolesVisible={2} />
                )}
              </Grid>
            </InfoCard>
          </Grid>
          <Grid item xs={12} md={8}>
            <InfoCard variant="gridItem">
              <div>
                <Typography className={classes.tokenText}>
                  Authorization Token
                </Typography>
                <div className={classes.copyToken}>
                  <div className={classes.tokenDiv}>
                    <Typography className={classes.token} id="id-token">
                      {token}
                    </Typography>
                  </div>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: '24px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(token);
                      setCopyButtonLabel('COPIED');
                      setTimeout(() => {
                        setCopyButtonLabel('Copy');
                      }, 2000);
                    }}
                    disabled={copyButtonLabel === 'COPIED'}
                  >
                    {copyButtonLabel}
                    <FileCopyIcon
                      style={{ marginLeft: '8px' }}
                      fontSize="small"
                    />
                  </Button>
                </div>
              </div>
              <AlertNotification
                severity="warning"
                alertMessage="Use at your own risk for experimentation or testing purposes.
                  Please talk to the Stargate team before using it in production"
                alertTitle="Attention!"
              />
            </InfoCard>
          </Grid>
        </Grid>
        <ProfileProjectTable
          data={userData?.projects?.filter((p: any) => !p.is_archived)}
          roles={roles}
        />
      </div>
      <Footer userJourney="Generic_v1" />
    </PageLayout>
  );
}

export const ProfilePage = () => {
  return <ProfilePageComponent />;
};
