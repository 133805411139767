import React, { useState } from 'react';
import { FormikErrors, FormikValues } from 'formik';
import ChipInput from 'material-ui-chip-input';
import { Chip, CircularProgress, Link } from '@material-ui/core';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import { INVALID_EMAIL_ERROR } from 'usg-types';
import { useStyles } from './styles';

export type SGChip = {
  value: string;
  isValid?: boolean;
  invalidFromApi?: boolean;
};

type Props = {
  field?: { name: string; value: SGChip[] };
  onChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<FormikValues>> | void;
  beforeAddValidityChecker?: (chip: string) => boolean;
  required?: boolean;
  patternMatch?: RegExp;
  checkValidity?: (chip: string) => Promise<boolean> | boolean;
  helperText?: string;
  errorText?: any;
  learnMoreText?: string;
  invalid?: boolean;
  handleDeleteCallBack?: (isError: boolean) => void;
  handleBeforeAddCallBack?: (isError: boolean) => void;
  handleLoadingCallBack?: (loading: boolean) => void;
  handleValidityCallback?: (isValid: boolean) => void;
  handleBlurInputCallBack?: (isValid: boolean) => void;
  handleChangeCallBack?: (emails: SGChip[]) => void;
  // All other props
  [prop: string]: any;
};

export const SGChipInput = ({
  field,
  onChange,
  required,
  patternMatch,
  beforeAddValidityChecker,
  checkValidity,
  helperText,
  errorText,
  learnMoreLink,
  invalid = false,
  handleDeleteCallBack,
  handleBeforeAddCallBack,
  handleLoadingCallBack,
  handleValidityCallback,
  handleBlurInputCallBack,
  handleChangeCallBack,
  ...props
}: Props) => {
  const { name = '', value = [] } = field ? field : {};
  const classes = useStyles();
  const [hasError, setHasError] = useState(value.some(item => !item.isValid));
  const [showGacError, setShowGacError] = useState(false);
  const [latestValue, setLatestValue] = useState(value);

  const isErrorInAnyChip = (chips: SGChip[]) => {
    return chips.some(chip => 'isValid' in chip && !chip.isValid);
  };

  const isApiErrorInAnyChip = (chips: SGChip[]) => {
    return chips.some(chip => 'invalidFromApi' in chip && chip.invalidFromApi);
  };

  React.useEffect(() => {
    setHasError(
      invalid || isErrorInAnyChip(value) || isApiErrorInAnyChip(value),
    );
  }, [value, invalid, errorText]);

  React.useEffect(() => {
    onChange(name, latestValue);
    if (handleChangeCallBack) handleChangeCallBack(latestValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestValue, onChange, name]);

  const isDuplicate = (chip: any) => {
    return value.some(v => v.value.toLowerCase() === chip.toLowerCase());
  };

  const handleAddChip = async (chip: any) => {
    if (isDuplicate(chip) || chip.trim() === '') return;
    if (handleLoadingCallBack) handleLoadingCallBack(true);
    const newChip = {
      value: chip,
    };
    setLatestValue(prevValue => [...prevValue, newChip]);
    const validity = checkValidity ? await checkValidity(chip) : true;

    let isStillLoading = false;
    setLatestValue(prevValue => {
      const latestChips = [...prevValue];
      const targetChipIndex = latestChips.findIndex(c => c.value === chip);
      latestChips[targetChipIndex].isValid = validity;
      isStillLoading = latestChips.some(c => !('isValid' in c));
      return latestChips;
    });
    if (!validity) {
      setHasError(true);
      setShowGacError(true);
    }
    if (handleValidityCallback) handleValidityCallback(validity);
    if (handleLoadingCallBack) handleLoadingCallBack(isStillLoading);
  };

  const handleDeleteChip = (_: any, index: number) => {
    const newValue = value.filter((__: any, i: number) => i !== index);
    const isError = isErrorInAnyChip(newValue);
    const isApiError = isApiErrorInAnyChip(newValue);
    setLatestValue(prevValue =>
      prevValue.filter((__: any, i: number) => i !== index),
    );
    setHasError(isError || invalid || isApiError);
    setShowGacError(isError || false);
    if (handleDeleteCallBack) handleDeleteCallBack(isError);
  };

  const onBeforeAdd = (chip: any) => {
    // check for pattern here, if matched then only return true
    let match = true;
    if (!chip) {
      return match;
    }

    if (patternMatch) {
      match = patternMatch.test(chip);
    }

    if (beforeAddValidityChecker) {
      match = beforeAddValidityChecker(chip);
    }

    if (!match) {
      setHasError(true);
      if (handleBeforeAddCallBack) handleBeforeAddCallBack(true);
    }

    return match;
  };

  const customChipRenderer = (
    {
      chip,
      isDisabled,
      isReadOnly,
      handleClick,
      handleDelete,
      className: chipClassName,
    }: any,
    key: any,
  ) => {
    let chipClass;
    let chipIcon;
    if (!('isValid' in chip)) {
      chipClass = classes.loadingChip;
      chipIcon = <CircularProgress />;
    } else if (
      !chip.isValid ||
      ('invalidFromApi' in chip && chip.invalidFromApi)
    ) {
      chipClass = classes.invalidChip;
      chipIcon = <PriorityHigh />;
    } else {
      chipClass = '';
      chipIcon = <></>;
    }
    return (
      <Chip
        key={key}
        className={`${chipClassName} ${classes.chip} ${chipClass}`}
        style={{
          pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
        }}
        onClick={handleClick}
        onDelete={handleDelete}
        label={chip.value}
        disabled={!('isValid' in chip)}
        icon={chipIcon}
      />
    );
  };

  const helperTextNode = () => {
    const error = showGacError ? (
      <>
        {`${INVALID_EMAIL_ERROR} `}
        <Link
          href={learnMoreLink}
          className={classes.learnMore}
          target="_blank"
        >
          Learn more.
        </Link>
      </>
    ) : (
      errorText
    );

    return (
      <span className={hasError ? classes.errorText : ''}>
        {hasError ? error : helperText}
      </span>
    );
  };

  const onBlurInput = () => {
    setHasError(
      invalid || isErrorInAnyChip(value) || isApiErrorInAnyChip(value),
    );
    if (handleBlurInputCallBack)
      handleBlurInputCallBack(invalid || value.some(item => !item.isValid));
  };

  const onPasteHandler = (
    clipboardEvent: React.ClipboardEvent<HTMLDivElement>,
  ) => {
    clipboardEvent.preventDefault();
    const rawTagsString = clipboardEvent.clipboardData.getData('text');
    const uniqueTagsSet = new Set(rawTagsString.split(/[ ,\s]+/));
    uniqueTagsSet.forEach(async (tag: string) => {
      if (onBeforeAdd(tag)) {
        await handleAddChip(tag);
      }
    });
  };

  return (
    <ChipInput
      required={required}
      value={value}
      onAdd={handleAddChip}
      onDelete={handleDeleteChip}
      onBeforeAdd={onBeforeAdd}
      onBlur={onBlurInput}
      chipRenderer={customChipRenderer}
      className={
        !(props?.className || props.classes || props.styles)
          ? classes.defaultChipInputStyles
          : ''
      }
      {...props}
      error={hasError}
      helperText={helperTextNode()}
      InputProps={{
        ...props?.InputProps,
        name: name,
        id: `input_${name}`,
      }}
      onPaste={onPasteHandler}
      newChipKeyCodes={[32, 13, 188]} // add chips when the user hits enter, space or comma
    />
  );
};
