import React from 'react';
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextareaAutosize,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import {
  useApi,
  microsoftAuthApiRef,
  configApiRef,
  identityApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { ContactUsLink } from '../ContactUsLink';
import {
  getLocalStorageWithExpiry,
  submitNPSData,
  submitUserData,
  getNPSData,
} from 'sg-utils-frontend';
import { userJourneyTypes } from 'usg-types';
import { useAnalytics } from '@backstage/core-plugin-api';

import { useStyles } from './styles';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  3: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  4: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  5: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  6: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  7: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  8: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  9: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
  10: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
} as any;

function IconContainer(props: any) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

type Props = {
  dialogOpen: boolean;
  dialogOnCloseHelper: () => void;
  npsLocation: 'footer' | 'mainpage' | 'user-journey';
  userJourney: userJourneyTypes;
};

export const NetPromoterScore = ({
  dialogOpen,
  dialogOnCloseHelper,
  npsLocation,
  userJourney,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [ratingValue, setRatingValue] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [feedbackDescription, setFeedbackDescription] = React.useState('');
  const feedbackDescriptionTextMaxLength = 2000;
  const authref = useApi(microsoftAuthApiRef);
  const configApi = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const errorApi = useApi(errorApiRef);
  const analytics = useAnalytics();
  const NPSFrequency = 90;
  const language = getLocalStorageWithExpiry('locale');
  const skipBtnFrequencyDays = 7;

  const npsResponseFilter = (data: any, userJourneyValue: string) => {
    const filteredData = data?.data?.filter(
      (element: any) => element.user_journey_id === userJourneyValue,
    );
    if (filteredData.length > 1) {
      return [filteredData[length - 1]];
    }
    return filteredData;
  };

  const caseMainPage = async (
    url: any,
    token: any,
    currentDate: any,
    actionDate: any,
  ) => {
    const response: any = await getNPSData(url, token);
    const filteredResponse: any = await npsResponseFilter(
      response,
      userJourney,
    );
    if (
      filteredResponse?.length === 0 &&
      currentDate.getTime() >= actionDate.getTime()
    ) {
      setOpen(dialogOpen);
    } else if (filteredResponse?.length > 0) {
      const lastCreatedAt = filteredResponse[0]?.created_at;
      const lastNpsDate = new Date(lastCreatedAt);
      lastNpsDate.setDate(lastNpsDate.getDate() + NPSFrequency); // lastNpsDate + Three Months(90 days)
      if (
        currentDate.getTime() >= lastNpsDate.getTime() &&
        currentDate.getTime() >= actionDate.getTime()
      ) {
        setOpen(dialogOpen);
      } else {
        dialogOnCloseHelper();
      }
    } else {
      dialogOnCloseHelper();
    }
  };

  const caseUserJourney = async (url: any, token: any, currentDate: any) => {
    const response: any = await getNPSData(url, token);
    const filteredResponse: any = await npsResponseFilter(
      response,
      userJourney,
    );
    if (filteredResponse?.length > 0) {
      const lastCreatedAt = filteredResponse[0]?.created_at;
      const lastNpsDate = new Date(lastCreatedAt);
      lastNpsDate.setDate(lastNpsDate.getDate() + NPSFrequency); // lastNpsDate + Three Months(90 days)
      if (currentDate.getTime() >= lastNpsDate.getTime()) {
        setOpen(dialogOpen);
      } else {
        dialogOnCloseHelper();
      }
    } else {
      setOpen(dialogOpen);
    }
  };

  React.useEffect(() => {
    async function process() {
      const nextActionDate: any = localStorage.getItem('nextActiondate');
      const actionDate = new Date(nextActionDate);
      const currentDate = new Date();
      const loggedInUser = await authref.getProfile();
      const backendUrl = configApi.getString('backend.baseUrl');
      const url = `${backendUrl}/api/home/nps/${loggedInUser?.email}`;
      const token = (await identityApi.getCredentials()).token;
      if (token) {
        switch (npsLocation) {
          case 'mainpage': {
            caseMainPage(url, token, currentDate, actionDate);
            break;
          }
          case 'user-journey': {
            caseUserJourney(url, token, currentDate);
            break;
          }
          default: {
            setOpen(dialogOpen);
            break;
          }
        }
      }
    }
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  const handleClose = () => {
    setOpen(false);
    dialogOnCloseHelper();
    setSuccessMsg(false);
    if (npsLocation === 'mainpage') {
      const dt: any = new Date();
      dt.setDate(dt.getDate() + skipBtnFrequencyDays);
      localStorage.setItem('nextActiondate', dt);
    }
  };
  const handleSkipBtn = () => {
    setOpen(false);
    dialogOnCloseHelper();
    setSuccessMsg(false);
    if (npsLocation === 'mainpage') {
      const dt: any = new Date();
      dt.setDate(dt.getDate() + skipBtnFrequencyDays);
      localStorage.setItem('nextActiondate', dt);
    }
  };
  const handleDialogClose = () => {
    handleClose();
  };
  const handleRating = (e: any) => {
    if (ratingValue === e.target.value) {
      setRatingValue('');
    } else {
      setRatingValue(e.target.value);
    }
  };
  const handleChange = (e: any) => {
    if (e.target.value.length <= feedbackDescriptionTextMaxLength) {
      setFeedbackDescription(e.target.value);
    }
  };

  const handleSubmitRating = async () => {
    const auth = await authref.getProfile();
    const idToken = await authref.getIdToken();
    const loggedInUser: any = auth?.email;

    const utcDate = new Date();
    analytics.captureEvent('netpromoterscore_v2', userJourney, {
      attributes: {
        nps_v2_date: utcDate.toISOString(),
        nps_v2_email: loggedInUser,
        nps_v2_rating: ratingValue,
        nps_v2_feedback: feedbackDescription,
      },
    });

    const dt: any = new Date();
    dt.setDate(dt.getDate() + NPSFrequency);
    localStorage.setItem('nextActiondate', dt);

    try {
      const backendUrl = configApi.getString('backend.baseUrl');
      const token = (await identityApi.getCredentials()).token;

      // create user in db first to send user_id with NPS data
      let url = `${backendUrl}/api/home/users`;
      const userData = {
        email: loggedInUser,
        idToken: idToken,
      };

      const user = await submitUserData(url, token, userData);

      url = `${backendUrl}/api/home/nps`;
      const npsData = {
        user_email: loggedInUser,
        nps_rating: ratingValue,
        feedback: feedbackDescription,
        user_journey_id: userJourney,
        user_id: user?.data.id,
      };
      await submitNPSData(url, token, npsData);
      setSuccessMsg(true);
    } catch (err: any) {
      errorApi.post(new Error(`${err.message}`));
    }
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {successMsg ? (
        <>
          <DialogContent style={{ fontWeight: 'bold' }}>
            Thank you for your feedback!
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              size="small"
              id="close-submitted-dialog-button"
              onClick={handleDialogClose}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            Hello Stargate User
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" paragraph>
              Please rate your experience of using Stargate to help us to make
              it better.
            </Typography>
            <Rating
              name="customized-icons"
              size="large"
              // defaultValue={2}
              className={classes.emojiIcon}
              max={10}
              onChange={handleRating}
              getLabelText={value => customIcons[value].label}
              IconContainerComponent={IconContainer}
              id="select-nps-rating"
            />
            <div className={classes.ratingText}>
              <span>Poor</span>
              <span className={classes.excellentText}>Excellent</span>
            </div>
            <div className={classes.textArea}>
              <Typography variant="body2" paragraph>
                Please tell us why you chose that score.
              </Typography>
              <TextareaAutosize
                id="justification-input"
                aria-label="minimum height"
                minRows={3}
                minLength={20}
                value={feedbackDescription}
                // placeholder="Please enter feedback"
                style={{ width: 500, height: 80 }}
                onChange={handleChange}
              />
              <Typography
                variant="body2"
                className={classes.descriptionHelperText}
                paragraph
              >
                Maximum characters allowed = {feedbackDescriptionTextMaxLength}
              </Typography>
            </div>
          </DialogContent>

          <DialogActions className={classes.actions}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleSkipBtn}
              id="skip-nps-button"
            >
              Skip for now
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmitRating}
              disabled={ratingValue === ''}
              id="submit-nps-button"
            >
              Submit
            </Button>
          </DialogActions>
          <div className={classes.contactUs}>
            <Typography variant="body2" paragraph>
              {`Have more feedback for us? `}
              <ContactUsLink text="Contact us" language={language} />
            </Typography>
          </div>
        </>
      )}
    </Dialog>
  );
};
