import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
} from 'usg-types';

interface OutputType {
  gh_emu_read_orgs: string[];
  gh_emu_read_repos: string[];
  ghe_read_orgs: string[];
  ghe_read_repos: string[];
  gh_emu_read_write_orgs: string[];
  gh_emu_read_write_repos: string[];
  ghe_read_write_orgs: string[];
  ghe_read_write_repos: string[];
  gh_emu_read_write_delete_orgs: string[];
  gh_emu_read_write_delete_repos: string[];
  ghe_read_write_delete_orgs: string[];
  ghe_read_write_delete_repos: string[];
}

/**
 * Checks if the first resource's dev_tool_id matches the Artifactory dev tool ID and, if so,
 * fetches artifactory properties and sets them using the provided setArtifactoryProperties function.
 *
 * @param {any} data - Data object containing the dev tool ID and other properties.
 * @param {string} projectId - The project's unique identifier.
 * @param {any} authRef - Reference to an authentication object capable of obtaining an ID token.
 * @param {any} projectApi - Object containing methods to interact with the project API.
 * @param {string} artifactName - Name of Artifact. It can be artifactory_self_hosted or artifactory_saas
 * @param {Function} setArtifactoryProperties - Function to set the artifactory properties.
 * @param {Function} setExistingArtifactoryProperties - Function to set the existing artifactory properties. This won't be changed
 * @param {Function} setIsLoadingArtifactoryProperties - Function to set the loading of artifactory properties.
 */
async function fetchAndSetArtifactoryProperties(
  data: any,
  project_id: string,
  authref: any,
  projectApi: any,
  artifactName: string,
  setArtifactoryProperties: Function,
  setExistingArtifactoryProperties: Function,
  setIsLoadingArtifactoryProperties: Function,
) {
  if (
    data?.response?.data?.resources[0].dev_tool_id ===
      ARTIFACTORY_DEV_TOOL_ID ||
    data?.response?.data?.resources[0].dev_tool_id ===
      ARTIFACTORY_SAAS_DEV_TOOL_ID
  ) {
    const token = await authref.getIdToken();
    const generatedArtifactName = generateRegionalSpecificArtifactNameForSAAS(
      data?.response?.data?.resources[0].dev_tool_id,
      artifactName,
      data?.response?.data?.resources[0].config,
    );
    const res = await projectApi.getArtifactoryAccessProperties(
      project_id,
      token,
      {
        artifactory_name: generatedArtifactName,
        artifact_repo_key: data?.response?.data?.resources[0].key,
      },
    );
    if (res.response.data[1] === 200) {
      // Transform and set the artifactory properties based on the response
      const combinedData = transformResponseData(res.response.data[0]);
      setArtifactoryProperties(combinedData);
      setExistingArtifactoryProperties(combinedData);
    }

    setIsLoadingArtifactoryProperties(false);
  }
}

/**
 * Transforms response data into a structured format suitable for setting or updating artifactory properties.
 *
 * @param {any} responseData - The data received from the artifactory properties API response.
 * @returns {Array} A structured array of combined artifactory properties.
 */
function transformResponseData(responseData: any) {
  // Initialize an array to hold the combined data
  const combinedData: any[] = [];

  // Define mappings for permissions and platforms
  const mappings = [
    { key: 'gh_emu_read_orgs', type: 'Read-only', platform: 'EMU' },
    { key: 'gh_emu_read_repos', type: 'Read-only', platform: 'EMU' },
    { key: 'ghe_read_orgs', type: 'Read-only', platform: 'Enterprise' },
    { key: 'ghe_read_repos', type: 'Read-only', platform: 'Enterprise' },
    { key: 'gh_emu_read_write_orgs', type: 'Read-Write', platform: 'EMU' },
    { key: 'gh_emu_read_write_repos', type: 'Read-Write', platform: 'EMU' },
    { key: 'ghe_read_write_orgs', type: 'Read-Write', platform: 'Enterprise' },
    { key: 'ghe_read_write_repos', type: 'Read-Write', platform: 'Enterprise' },
    {
      key: 'gh_emu_read_write_delete_orgs',
      type: 'Read-Write-Delete',
      platform: 'EMU',
    },
    {
      key: 'gh_emu_read_write_delete_repos',
      type: 'Read-Write-Delete',
      platform: 'EMU',
    },
    {
      key: 'ghe_read_write_delete_orgs',
      type: 'Read-Write-Delete',
      platform: 'Enterprise',
    },
    {
      key: 'ghe_read_write_delete_repos',
      type: 'Read-Write-Delete',
      platform: 'Enterprise',
    },
  ];

  // Iterate over each mapping and transform the data accordingly
  mappings.forEach(mapping => {
    if (responseData[mapping.key]) {
      combinedData.push(
        ...responseData[mapping.key].map((name: string) => ({
          name,
          type: mapping.type,
          platform: mapping.platform,
        })),
      );
    }
  });

  combinedData.sort((a, b) => a.name.localeCompare(b.name));

  return combinedData;
}

/**
 * Updates the artifactory properties for a given project.
 * @param {any} resourceData - Data object containing the dev tool ID and other properties.
 * @param {any} projectApi - Object containing methods to interact with the project API.
 * @param {string} idToken - Authentication token.
 * @param {string} projectId - The project's unique identifier.
 * @param {string} artifactName - Name of Artifact. It can be artifactory_self_hosted or artifactory_saas
 * @param {string} artifactRepoKey - The key of the artifact repository.
 * @param {Array} artifactoryProperties - Array of artifactory properties to update.
 */
async function updateArtifactoryProperties(
  resourceData: any,
  projectApi: any,
  idToken: string,
  projectId: string,
  artifactName: string,
  artifactRepoKey: string,
  artifactoryProperties: Array<any>,
) {
  if (!artifactoryProperties) {
    return;
  }
  // Filter out items where name, type, or platform is empty
  const filteredArtifactoryProperties = artifactoryProperties.filter(
    item => item.name && item.type && item.platform,
  );
  const transformedData = transformData(filteredArtifactoryProperties);
  const generatedArtifactName = generateRegionalSpecificArtifactNameForSAAS(
    resourceData.dev_tool_id,
    artifactName,
    resourceData.config,
  );

  await projectApi.updateArtifactoryAccessProperties(
    projectId,
    idToken,
    {
      artifactory_name: generatedArtifactName,
      artifact_repo_key: artifactRepoKey,
    },
    transformedData,
  );
}

/**
 * Transforms structured artifactory properties into a format suitable for API submission.
 *
 * @param {Array} input - Structured artifactory properties.
 * @returns {Object} Transformed data for API submission.
 */
function transformData(input: Array<any>) {
  const output: OutputType = {
    gh_emu_read_orgs: [],
    gh_emu_read_repos: [],
    ghe_read_orgs: [],
    ghe_read_repos: [],
    gh_emu_read_write_orgs: [],
    gh_emu_read_write_repos: [],
    ghe_read_write_orgs: [],
    ghe_read_write_repos: [],
    gh_emu_read_write_delete_orgs: [],
    gh_emu_read_write_delete_repos: [],
    ghe_read_write_delete_orgs: [],
    ghe_read_write_delete_repos: [],
  };

  input.forEach(item => {
    let keyPrefix = 'gh'; // Default prefix for GitHub
    if (item.platform === 'Enterprise') {
      keyPrefix = 'ghe';
    }

    let keySuffix = '';
    switch (item.type) {
      case 'Read-only':
        keySuffix = 'read';
        break;
      case 'Read-Write':
        keySuffix = 'read_write';
        break;
      case 'Read-Write-Delete':
        keySuffix = 'read_write_delete';
        break;
      default:
        // Handle other types if necessary
        break;
    }

    const keyType = item.name.includes('/') ? 'repos' : 'orgs';
    let key = `${keyPrefix}_emu_${keySuffix}_${keyType}`;

    // Special handling for GitHub Enterprise without the 'emu' part
    if (item.platform === 'Enterprise') {
      key = `${keyPrefix}_${keySuffix}_${keyType}`;
    }

    if (key in output) {
      output[key as keyof OutputType].push(item.name);
    }
  });

  return output;
}

function generateRegionalSpecificArtifactNameForSAAS(
  devToolID: any,
  artifactName: string,
  config: any,
) {
  if (devToolID !== ARTIFACTORY_SAAS_DEV_TOOL_ID) {
    return artifactName;
  }
  const rclass = config?.rclass;
  const sites = config?.sites;
  if (rclass === 'local') {
    return `${artifactName}_${sites[0]}`;
  }
  return artifactName;
}

function validateArtifactoryPropertiesPostUserInput(
  artifactoryProperties: Array<any>,
) {
  const seen = new Map();
  const nameFormatRegex = /^[a-zA-Z0-9-_]+(\/[a-zA-Z0-9-_]+)?$/;

  if (!artifactoryProperties) {
    return {
      isValid: true,
      msg: 'Validation successful: Identified no Artifactory properties',
    };
  }
  for (let i = 0; i < artifactoryProperties.length; i++) {
    const item = artifactoryProperties[i];
    const key = `${item.name}-${item.platform}`;

    // Empty name check
    if (!item.name) {
      return {
        isValid: false,
        msg: `Validation failed: The name field is empty at position ${i + 1}.`,
      };
    }

    // Name format check
    if (!nameFormatRegex.test(item.name)) {
      return {
        isValid: false,
        msg: `Validation failed: The name "${item.name}" at position ${
          i + 1
        } does not match the required format "github-org" or "github-org/repo-name".`,
      };
    }

    // Duplicate check
    if (seen.has(key.toLowerCase())) {
      return {
        isValid: false,
        msg: `Validation failed: Duplicate entry found for name "${item.name}" with platform "${item.platform}".`,
      };
    }
    seen.set(key.toLowerCase(), true);
  }

  // If all checks pass
  return {
    isValid: true,
    msg: 'Validation successful: No duplicates and no empty names found.',
  };
}

// Export the function
export {
  fetchAndSetArtifactoryProperties,
  updateArtifactoryProperties,
  validateArtifactoryPropertiesPostUserInput,
};
