import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  DialogTitle,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Collapse,
} from '@material-ui/core';
import { useStyles } from './styles';
import { TransitionGroup } from 'react-transition-group';
import CloseIcon from '@material-ui/icons/Close';
import { projectApiRef } from '../../api';
import { useAsyncFn } from 'react-use';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  GITHUBEMU_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
  NON_SELECTABLE_RESOURCE_TYPES,
  REGEX_ARTIFACTORY_SAAS,
  REGEX_GITHUB_EMU,
} from 'usg-types';
import {
  devToolSupportsDynamicGroup,
  isAttachResourcesRoleRequired,
  commonHideOwnerResources,
  transformResourceName,
  isDynamicGroup,
} from 'sg-utils-frontend';

type ResourceObject = {
  attachedResources: {
    config: {};
    dev_tool_id: number;
    dev_tool_name: string;
    environment: string;
    generated_resource_key: string | number;
    id: number;
    key: string;
    name: string;
    role: string;
    url: string;
  }[];
  dev_tool_id: number;
  dev_tool_name: string;
};
type SortedResourcesArrayType = ResourceObject[];

export const AttachResource = (props: {
  isVisible: boolean;
  handleClose: any;
  projectId: string;
  groupId: string;
  resources: any;
  devTools: any;
  groupData: any;
  isAdmin: boolean;
  handleSuccess: any;
  setResourcesResult: any;
}) => {
  const {
    isVisible,
    handleClose,
    projectId,
    groupId,
    resources,
    handleSuccess,
    setResourcesResult,
    devTools,
    groupData,
    isAdmin,
  } = props;

  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const [attachError, setAttachError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');

  // To filter Artifactory(attachedResources) where RClass !== 'virtual'
  const removeVirtualArtifactoryResource = (array: any[]) => {
    return array
      .map(element => {
        if (element.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID) {
          const newElement = element;
          const artifactoryAttachedResources =
            element?.attachedResources.filter((elementTwo: any) => {
              return !NON_SELECTABLE_RESOURCE_TYPES.includes(
                elementTwo.config?.rclass,
              );
            });
          return {
            ...newElement,
            attachedResources: artifactoryAttachedResources,
          };
        }
        return element;
      })
      .filter(e => e.attachedResources.length !== 0);
  };

  const filterResourcesForDynamicGroups = (
    admin: boolean,
    userGroupName: string,
    resourcesArray: any[],
  ) => {
    if (!admin) {
      const isGithubEmuUserGroup = REGEX_GITHUB_EMU.test(userGroupName);
      const isArtifactorySaasUserGroup =
        REGEX_ARTIFACTORY_SAAS.test(userGroupName);

      if (isGithubEmuUserGroup) {
        return resourcesArray.filter(
          element => element.dev_tool_id === GITHUBEMU_DEV_TOOL_ID,
        );
      }

      if (isArtifactorySaasUserGroup) {
        return resourcesArray.filter(
          element => element.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID,
        );
      }
    }
    return resourcesArray;
  };

  const sortedResourcesArray: SortedResourcesArrayType = React.useMemo(() => {
    const dynamicGroup = isDynamicGroup(groupData);
    const tempArray: any[] = [];
    for (const key in resources) {
      if (Object.prototype.hasOwnProperty.call(resources, key)) {
        if (dynamicGroup) {
          const foundTool = devTools.find(
            (dt: any) => dt.id === resources[key]?.dev_tool_id,
          );
          if (!devToolSupportsDynamicGroup(foundTool)) {
            continue;
          }
        }
        tempArray.push(resources[key]);
      }
    }
    const filteredArray = removeVirtualArtifactoryResource(tempArray);
    const newFilteredArray = dynamicGroup
      ? filterResourcesForDynamicGroups(isAdmin, groupData?.name, filteredArray)
      : filteredArray;
    const finalSortedArray = newFilteredArray.sort(
      (a: ResourceObject, b: ResourceObject) => {
        if (a.dev_tool_name < b.dev_tool_name) {
          return -1;
        }
        if (a.dev_tool_name > b.dev_tool_name) {
          return 1;
        }
        return 0;
      },
    );
    return finalSortedArray;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, devTools, groupData]);

  const resourceNameData = (dev_tool_id: number, data: any) => {
    let tempData = data;
    if (dev_tool_id === ARTIFACTORY_DEV_TOOL_ID) {
      tempData = data?.filter((resource: any) => {
        // if no config is provided then true
        if (resource?.config) {
          return !NON_SELECTABLE_RESOURCE_TYPES.includes(
            resource.config?.rclass,
          );
        }
        return true;
      });
    }
    const finalSortedArray = tempData?.sort((a: any, b: any) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );
    return finalSortedArray?.map((r: any) => {
      return (
        <MenuItem
          key={`resource_id_${r.id}-${r.environment}`}
          value={r.id}
          title={r.name}
          className={classes.resourceName}
        >
          {r.name} {r.environment !== 'prod' && `(${r.environment})`}
        </MenuItem>
      );
    });
  };
  const [resourceAttachmentRequest, setResourceAttachmentRequest] = useState([
    {
      devtool_id: '',
      resource_name: '',
      resource_id: '',
      role: '',
    },
  ]);

  const [{ loading: reqLoading = false as boolean }, attachResources] =
    useAsyncFn(async (request: any) => {
      const token = await authref.getIdToken();
      const isSuccess: Array<{}> = [];
      const isFail: Array<{}> = [];

      const promises = request?.map(async (resource: any) => {
        const paramsdata = {
          idToken: token,
          userGroupRoles: [
            {
              op: 'add',
              value: [{ id: parseInt(groupId, 10), role: resource.role }],
            },
          ],
        };
        const res = await projectApi.assignUserGroupToResources(
          paramsdata,
          projectId,
          resource.id,
        );
        if (res?.status === 200) {
          isSuccess.push({ name: resource.name });
        } else {
          isFail.push({
            name: resource.name,
            status: res?.status,
            message: res?.data?.message,
          });
        }
      });
      await Promise.allSettled(promises).catch(e => {
        setAttachError(e?.message);
      });

      handleSuccess();
      setResourcesResult({ success: isSuccess, fail: isFail });
      setResourceAttachmentRequest([
        {
          devtool_id: '',
          resource_name: '',
          resource_id: '',
          role: '',
        },
      ]);
      handleClose();
    }, []);
  const findOutDuplicates = (i: any) => {
    const finalData = resourceAttachmentRequest.filter((_, fi) => i !== fi);
    const resourceIds = finalData.map(item => {
      return item?.resource_id;
    });
    const removeEmptyData = resourceIds.filter((id: string) =>
      id.length > 0 ? false : id,
    );
    const isDuplicate =
      removeEmptyData.length !== new Set(removeEmptyData).size;
    let duplicateMsg = '';
    if (isDuplicate) {
      duplicateMsg = 'You cannot assign the same resource twice.';
    }
    setDuplicateError(duplicateMsg);
  };
  const handleResourceTypeChange = (event: any, i: number) => {
    let row = {
      ...resourceAttachmentRequest[i],
      [event.target.name]: event.target.value,
    };
    resourceAttachmentRequest.map((x, idx) => (idx === i ? row : x));
    if (event.target.name === 'resource_id') {
      setDuplicateError('');
      findOutDuplicates(i);
      resourceAttachmentRequest.map(x =>
        x?.resource_id === row?.resource_id
          ? setDuplicateError('You cannot assign the same resource twice.')
          : '',
      );
      const selected_resource = resources[
        row.devtool_id
      ]?.attachedResources.find((r: any) => r.id === event.target.value);
      row = {
        ...row,
        resource_name: selected_resource.name,
      };
    } else if (event.target.name === 'devtool_id') {
      row = {
        ...resourceAttachmentRequest[i],
        devtool_id: event.target.value,
        resource_id: '',
        role: '',
      };
    }
    setResourceAttachmentRequest([
      ...resourceAttachmentRequest.map((x, idx) => (idx === i ? row : x)),
    ]);
  };

  useEffect(() => {
    setTimeout(() => {
      setAttachError('');
      setResourceAttachmentRequest([
        {
          devtool_id: '',
          resource_name: '',
          resource_id: '',
          role: '',
        },
      ]);
    }, 1000);
  }, [isVisible]);

  const resourceSelectRow = () => {
    return (
      <TransitionGroup>
        {resourceAttachmentRequest.map((rf, i) => (
          <Collapse>
            <div
              key={i}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '16px',
              }}
            >
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginRight: '16px' }}
              >
                <Typography
                  variant="button"
                  component="div"
                  style={{ marginBottom: '8px', textTransform: 'none' }}
                  id="sort-label"
                >
                  Resource Type
                </Typography>
                <Select
                  labelId="sort-label"
                  id="sort"
                  name="devtool_id"
                  value={rf.devtool_id}
                  onChange={e => handleResourceTypeChange(e, i)}
                  displayEmpty
                  disabled={sortedResourcesArray.length === 0}
                >
                  {sortedResourcesArray?.map((element: ResourceObject) => {
                    return isAdmin ? (
                      <MenuItem
                        key={`dev_tool_id_${element.dev_tool_id}`}
                        value={element.dev_tool_id}
                      >
                        {transformResourceName(element.dev_tool_name)}
                      </MenuItem>
                    ) : (
                      !commonHideOwnerResources(element.dev_tool_id) && (
                        <MenuItem
                          key={`dev_tool_id_${element.dev_tool_id}`}
                          value={element.dev_tool_id}
                        >
                          {transformResourceName(element.dev_tool_name)}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <Typography
                  variant="button"
                  component="div"
                  style={{ marginBottom: '8px', textTransform: 'none' }}
                  id="sort-label"
                >
                  Resource Name
                </Typography>
                <Select
                  labelId="sort-label"
                  value={rf.resource_id}
                  name="resource_id"
                  onChange={e => handleResourceTypeChange(e, i)}
                  displayEmpty
                  disabled={!rf.devtool_id}
                >
                  {resourceNameData(
                    Number(rf.devtool_id),
                    resources[rf.devtool_id]?.attachedResources,
                  )}
                </Select>
              </FormControl>
              {devTools.find((dt: any) => dt.id === rf.devtool_id)?.roles
                .length !== 0 && (
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginLeft: '16px' }}
                >
                  <Typography
                    variant="button"
                    component="div"
                    style={{ marginBottom: '8px', textTransform: 'none' }}
                    id="sort-label"
                  >
                    Resource Role
                  </Typography>
                  <Select
                    labelId="sort-label"
                    id="sort"
                    name="role"
                    value={rf.role}
                    onChange={e => handleResourceTypeChange(e, i)}
                    displayEmpty
                    disabled={!rf.resource_id && !rf.resource_id}
                  >
                    {devTools
                      .find((dt: any) => dt.id === rf.devtool_id)
                      ?.roles.map((r: any) => {
                        return (
                          <MenuItem key={`role_${r.name}`} value={r.name}>
                            {r.display_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}

              <IconButton
                disabled={reqLoading}
                className={classes.deleteIcon}
                onClick={() => {
                  setResourceAttachmentRequest([
                    ...resourceAttachmentRequest.filter((_, fi) => i !== fi),
                  ]);
                  setAttachError('');
                  setDuplicateError('');
                  findOutDuplicates(i);
                }}
              >
                <DeleteIcon
                  className={[
                    reqLoading ? classes.disabledIconColor : classes.iconColor,
                  ].join(' ')}
                />
              </IconButton>
            </div>
          </Collapse>
        ))}
      </TransitionGroup>
    );
  };

  const addResourceHandler = () => {
    setResourceAttachmentRequest([
      ...resourceAttachmentRequest,
      {
        devtool_id: '',
        resource_name: '',
        resource_id: '',
        role: '',
      },
    ]);
    setAttachError('');
  };
  const handleCloseAssignResource = () => {
    handleClose();
    setResourceAttachmentRequest([
      {
        devtool_id: '',
        resource_name: '',
        resource_id: '',
        role: '',
      },
    ]);
    setDuplicateError('');
  };

  const collectDynamicGroupEnabledDevtools = (): string[] => {
    const names: string[] = devTools
      .filter((obj: any) => devToolSupportsDynamicGroup(obj))
      .map((obj: { name: string }) => transformResourceName(obj.name));
    const uniqueNames = Array.from(new Set(names));
    return uniqueNames;
  };

  // check if all roles are selected properly with required data
  const isRoleSelected = resourceAttachmentRequest.every((item: any) => {
    return isAttachResourcesRoleRequired(
      item.devtool_id,
      item.resource_id,
      item.role,
    );
  });
  const isAssignResourceDisable =
    resourceAttachmentRequest.length === 0 ||
    !isRoleSelected ||
    reqLoading ||
    duplicateError !== '';

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isVisible}
      onClose={() => {
        handleCloseAssignResource();
      }}
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          Assign Resources
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              aria-label="close"
              className={classes.iconContainer}
              onClick={() => {
                handleCloseAssignResource();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: '16px' }}>
          You need to assign permissions to resources from this project to share
          with the external group.
          <br />
          {`If you want to attach a ${transformResourceName(
            'Mt. Fuji',
          )} or Vault resource kindly check the
          documentation for manual steps.`}
          {isDynamicGroup(groupData) && (
            <>
              <br />
              Dynamic groups may only be assigned to the following devtools:{' '}
              {collectDynamicGroupEnabledDevtools().join(', ')}.
            </>
          )}
        </div>
        {resourceSelectRow()}
        {sortedResourcesArray.length === 0 && (
          <div className={classes.errorMsg}>
            There are no resources available to be assigned.
          </div>
        )}
        <Button
          fullWidth
          className={classes.hyperButton}
          color="default"
          onClick={addResourceHandler}
          disabled={reqLoading}
        >
          Add resource +
        </Button>
        {(attachError || duplicateError) && (
          <Typography
            style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
          >
            Error: {attachError || duplicateError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            handleClose();
            setResourceAttachmentRequest([
              {
                devtool_id: '',
                resource_name: '',
                resource_id: '',
                role: '',
              },
            ]);
          }}
          disabled={reqLoading}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            const accessible_resources = resourceAttachmentRequest.map(x => {
              return { id: x.resource_id, role: x.role, name: x.resource_name };
            });

            attachResources(accessible_resources);
          }}
          disabled={isAssignResourceDisable}
        >
          ASSIGN Resource
        </Button>
      </DialogActions>
    </Dialog>
  );
};
