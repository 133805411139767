import React, { useState, ReactNode } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  GITHUBEMU_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
} from 'usg-types';
import { projectApiRef } from '@internal/plugin-projects';
import { useForm } from 'react-hook-form';

export type GithubOrganizationInput = {
  organization_name: string;
  organization_description: string;
};

type allowedDevTools = typeof GITHUBEMU_DEV_TOOL_ID | typeof GITHUB_DEV_TOOL_ID;
export type CreateGithubOrgDialogProps = {
  open: boolean;
  handleDialogClose: any;
  stargateProjectId: number;
  devTool: allowedDevTools;
  defaultValues?: GithubOrganizationInput;
};
export const CreateGithubOrgDialog = (props: CreateGithubOrgDialogProps) => {
  const { open, handleDialogClose, stargateProjectId, devTool, defaultValues } =
    props;
  const [nameDirty, setNameDirty] = useState(false);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset: resetForm,
  } = useForm<GithubOrganizationInput>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const formCopy: { [key in allowedDevTools]: ReactNode } = {
    [GITHUBEMU_DEV_TOOL_ID]: (
      <p>This will create a new organization on Stargate GitHub EMU.</p>
    ),
    [GITHUB_DEV_TOOL_ID]: (
      <p>This will create a new organization on Stargate GitHub Enterprise.</p>
    ),
  };

  const onSubmit = async (data: GithubOrganizationInput) => {
    const idToken = await authref.getIdToken();
    const resp: any = await projectApi.createNewProjectResource(
      stargateProjectId.toString(),
      {
        idToken: idToken,
        key: data.organization_name.toLowerCase(),
        name: data.organization_name.toLowerCase(),
        description: data.organization_description,
        dev_tool_id: devTool,
      },
    );
    let fine = true;
    const code = resp.response?.status;
    if (code >= 400) {
      const errorMsg = resp?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      fine = false;
    }
    if (fine) {
      handleDialogClose(resp.response?.data);
      setNameDirty(false);
      resetForm();
    }
  };

  return (
    <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
      <DialogContent id="ug-create-confirmed-dialog">
        <div style={{ paddingBottom: '24px' }}>
          <h1>Create Github Organization</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>{formCopy[devTool]}</div>
            <div>
              <InputLabel error={!isValid} htmlFor="organization_name">
                Github Organization Name*
              </InputLabel>
              <TextField
                style={{ paddingBottom: '0%' }}
                type="text"
                id="organization_name"
                defaultValue={defaultValues?.organization_name}
                error={!isValid && nameDirty}
                onClick={() => setNameDirty(true)}
                fullWidth
                helperText={`Provide a unique name for your GitHub organization. Only letters, numbers and single hyphens allowed. Must not end with hyphen. No. of Characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.github}, Max=${RESOURCE_NAME_MAX_LENGTH.github}`}
                {...register('organization_name', {
                  required: true,
                  pattern: /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/,
                  minLength: RESOURCE_NAME_MIN_LENGTH.github,
                  maxLength: RESOURCE_NAME_MAX_LENGTH.github,
                })}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputLabel htmlFor="organization_description">
                Description
              </InputLabel>
              <TextField
                type="text"
                id="organization_description"
                defaultValue={defaultValues?.organization_description}
                rows={4}
                multiline
                fullWidth
                helperText={`Provide a brief description for your GitHub organization to explain what it is for. The description shows up on your organization's profile page. Number of characters allowed Max = ${GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH}`}
                placeholder="Example: GitHub organization for developing vehicle software component."
                {...register('organization_description', {
                  maxLength: GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
                })}
              />
            </div>
            <div style={{ paddingTop: '5%' }}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Button
                    variant="outlined"
                    type="reset"
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={1}>
                  <Button
                    id="create-ug-submit-button"
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
