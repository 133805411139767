import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  DialogTitle,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Collapse,
} from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import CloseIcon from '@material-ui/icons/Close';
import { projectApiRef } from '../../api';
import { useAsyncFn } from 'react-use';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import DeleteIcon from '@material-ui/icons/Delete';
import { isAttachResourcesRoleRequired } from 'sg-utils-frontend';

import { useStyles } from './styles';

export const AttachUserGroupToResource = (props: {
  isVisible: boolean;
  handleClose: any;
  projectId: string;
  userGroups: any;
  isAdmin: boolean;
  resourceData: any;
  handleSuccess: any;
}) => {
  const {
    isVisible,
    handleClose,
    projectId,
    userGroups,
    handleSuccess,
    resourceData,
  } = props;

  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const [attachError, setAttachError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');

  const [userGroupAttachmentRequest, setUserGroupAttachmentRequest] = useState([
    {
      usergroup_name: '',
      usergroup_id: '',
      role: '',
    },
  ]);
  const userGroupDropdownList = (data: any) => {
    const tempData = data;
    const finalSortedArray = tempData?.sort((a: any, b: any) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );
    return finalSortedArray?.map((r: any) => {
      return (
        <MenuItem
          key={`ug_id_${r.id}`}
          value={r.id}
          title={r.name}
          className={classes.resourceName}
        >
          {r.name}
        </MenuItem>
      );
    });
  };

  const [{ loading: reqLoading = false as boolean }, attachResources] =
    useAsyncFn(async (request: any) => {
      const token = await authref.getIdToken();
      // new api

      const userGroupRolesValues = request?.map((ug: any) => {
        return { id: parseInt(ug.id, 10), role: ug.role };
      });

      const paramsdata = {
        idToken: token,
        userGroupRoles: [
          {
            op: 'add',
            value: userGroupRolesValues,
          },
        ],
      };
      const res = await projectApi.assignUserGroupToResources(
        paramsdata,
        projectId,
        resourceData?.id,
      );
      if (res?.status === 200) {
        handleSuccess();
        setUserGroupAttachmentRequest([
          {
            usergroup_name: '',
            usergroup_id: '',
            role: '',
          },
        ]);
      } else {
        setAttachError(res?.data?.message);
      }

      handleClose();
    }, []);

  const findOutDuplicates = (i: any) => {
    const finalData = userGroupAttachmentRequest.filter((_, fi) => i !== fi);
    const resourceIds = finalData.map(item => {
      return item?.usergroup_id;
    });
    const removeEmptyData = resourceIds.filter((id: string) =>
      id.length > 0 ? false : id,
    );
    const isDuplicate =
      removeEmptyData.length !== new Set(removeEmptyData).size;
    let duplicateMsg = '';
    if (isDuplicate) {
      duplicateMsg = 'You cannot assign the same resource twice.';
    }
    setDuplicateError(duplicateMsg);
  };

  const handleResourceTypeChange = (event: any, i: number) => {
    let row = {
      ...userGroupAttachmentRequest[i],
      [event.target.name]: event.target.value,
    };
    userGroupAttachmentRequest.map((x, idx) => (idx === i ? row : x));
    if (event.target.name === 'usergroup_id') {
      setDuplicateError('');
      findOutDuplicates(i);
      userGroupAttachmentRequest.map(x =>
        x?.usergroup_id === row?.usergroup_id
          ? setDuplicateError('You cannot assign the same usergroup twice.')
          : '',
      );
      const selected_usergroup = userGroups.find(
        (r: any) => r.id === event.target.value,
      );
      row = {
        ...row,
        usergroup_name: selected_usergroup.name,
      };
    }
    setUserGroupAttachmentRequest([
      ...userGroupAttachmentRequest.map((x, idx) => (idx === i ? row : x)),
    ]);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAttachError('');
      setUserGroupAttachmentRequest([
        {
          usergroup_name: '',
          usergroup_id: '',
          role: '',
        },
      ]);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);

  const resourceSelectRow = (
    <TransitionGroup>
      {userGroupAttachmentRequest.map((rf, i) => (
        <Collapse>
          <div
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '16px',
            }}
          >
            <FormControl variant="outlined" fullWidth>
              <Typography
                variant="button"
                component="div"
                style={{ marginBottom: '8px', textTransform: 'none' }}
                id="sort-label"
              >
                User Group
              </Typography>
              <Select
                labelId="sort-label"
                value={rf.usergroup_id}
                name="usergroup_id"
                onChange={e => handleResourceTypeChange(e, i)}
                disabled={userGroups?.length === 0}
                displayEmpty
              >
                {userGroupDropdownList(userGroups)}
              </Select>
            </FormControl>
            {resourceData?.roles?.length !== 0 && (
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginLeft: '16px' }}
              >
                <Typography
                  variant="button"
                  component="div"
                  style={{ marginBottom: '8px', textTransform: 'none' }}
                  id="sort-label"
                >
                  Role
                </Typography>
                <Select
                  labelId="sort-label"
                  id="sort"
                  name="role"
                  value={rf.role}
                  onChange={e => handleResourceTypeChange(e, i)}
                  displayEmpty
                  disabled={!rf.usergroup_id && !rf.usergroup_id}
                >
                  {resourceData?.roles?.map((r: any) => {
                    return (
                      <MenuItem key={`role_${r.name}`} value={r.name}>
                        {r.display_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <IconButton
              disabled={reqLoading}
              className={classes.deleteIcon}
              onClick={() => {
                setUserGroupAttachmentRequest([
                  ...userGroupAttachmentRequest.filter((_, fi) => i !== fi),
                ]);
                setAttachError('');
                setDuplicateError('');
                findOutDuplicates(i);
              }}
            >
              <DeleteIcon
                className={[
                  reqLoading ? classes.disabledIconColor : classes.iconColor,
                ].join(' ')}
              />
            </IconButton>
          </div>
        </Collapse>
      ))}
    </TransitionGroup>
  );

  const addResourceHandler = () => {
    setUserGroupAttachmentRequest([
      ...userGroupAttachmentRequest,
      {
        usergroup_name: '',
        usergroup_id: '',
        role: '',
      },
    ]);
    setAttachError('');
  };
  const handleCloseAssignResource = () => {
    handleClose();
    setUserGroupAttachmentRequest([
      {
        usergroup_name: '',
        usergroup_id: '',
        role: '',
      },
    ]);
    setDuplicateError('');
  };

  // check if all roles are selected properly with required data
  const isRoleSelected = userGroupAttachmentRequest.every((item: any) => {
    return isAttachResourcesRoleRequired(
      resourceData?.dev_tool_id,
      item.usergroup_id,
      item.role,
    );
  });
  const isAssignResourceDisable =
    userGroupAttachmentRequest.length === 0 ||
    !isRoleSelected ||
    reqLoading ||
    duplicateError !== '';

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isVisible}
      onClose={() => {
        handleCloseAssignResource();
      }}
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          Assign User groups
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              aria-label="close"
              className={classes.iconContainer}
              onClick={() => {
                handleCloseAssignResource();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        {resourceSelectRow}
        {userGroups?.length === 0 && (
          <div className={classes.errorMsg}>
            There are no usergroups available to be assigned.
          </div>
        )}
        <Button
          fullWidth
          className={classes.hyperButton}
          color="default"
          onClick={addResourceHandler}
          disabled={reqLoading}
        >
          Add user group +
        </Button>
        {(attachError || duplicateError) && (
          <Typography
            style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
          >
            Error: {attachError || duplicateError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            handleClose();
            setUserGroupAttachmentRequest([
              {
                usergroup_name: '',
                usergroup_id: '',
                role: '',
              },
            ]);
          }}
          disabled={reqLoading}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            const accessible_usergroups = userGroupAttachmentRequest.map(x => {
              return {
                id: x.usergroup_id,
                role: x.role,
                name: x.usergroup_name,
              };
            });
            attachResources(accessible_usergroups);
          }}
          disabled={isAssignResourceDisable}
        >
          ASSIGN User group
        </Button>
      </DialogActions>
    </Dialog>
  );
};
