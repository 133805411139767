import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      color: theme.palette.usgColors?.primary.blue,
    },
  },
  support: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  artifactoryOwnerAlertText: {
    lineHeight: '16px',
    fontSize: '12px',
    marginTop: '10px',
    '& a': {
      cursor: 'pointer',
      fontWeight: 700,
    },
  },
  hyperLink: {
    color: theme.palette.usgColors?.primary.blue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  resourceList: {
    width: 'auto',
    height: 'auto',
    paddingRight: '3.5%',
  },
  resourceGray: {
    width: 'auto',
    height: 'auto',
    paddingRight: '3%',
    color: '#8b8b8b',
    display: 'flex',
    alignItems: 'center',
  },
  enviField: {
    paddingBottom: '12px',
  },
  comingSoon: {
    fontSize: 12,
  },
  accordion: {
    display: 'block',
  },
  cursor: {
    cursor: 'default !important',
  },
  formControl: {
    marginBottom: '20px',
  },
  infoIcon: {
    fontSize: '19px',
  },
  hyperlink: {
    color: '#0D6AFF',
    textDecoration: 'underline',
  },
  infoDiv: {
    display: 'flex',
    marginBottom: '10px',
    '& svg': {
      marginRight: '5px',
      color: '#1976d2',
    },
  },
}));
