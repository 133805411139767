import React from 'react';
import { Grid, InputLabel, TextField, Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_KEY_MIN_LENGTH,
  RESOURCE_KEY_MAX_LENGTH,
  RESOURCE_NAME_REGEX,
} from 'usg-types';

interface Props {
  onResourceSubmit: (values: any) => Promise<void>;
  nameDirty: boolean;
  projectData: any;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  keyDirty: boolean;
  onResourceKeyClick: () => void;
}
export const CreateConfluenceResourceForm = ({
  onResourceSubmit,
  nameDirty,
  projectData,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  keyDirty,
  onResourceKeyClick,
}: Props) => {
  return (
    <Grid item xs={6}>
      <Formik
        initialValues={{
          confluence_key: projectData?.response?.data?.key,
          confluence_name: '',
          confluence_description: '',
        }}
        onSubmit={onResourceSubmit}
      >
        {formik => (
          <Form>
            <h3 style={{ color: '#0E4295' }}>Confluence Space</h3>
            <InputLabel
              htmlFor="confluence_key"
              style={{
                color:
                  (formik.values.confluence_key.length <
                    RESOURCE_KEY_MIN_LENGTH.confluence &&
                    keyDirty) ||
                  (formik.values.confluence_key.length >
                    RESOURCE_KEY_MAX_LENGTH.confluence &&
                    keyDirty)
                    ? 'red'
                    : 'black',
              }}
            >
              Confluence Space Key*
            </InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="confluence_key"
              name="confluence_key"
              required
              error={
                (formik.values.confluence_key.length <
                  RESOURCE_KEY_MIN_LENGTH.confluence &&
                  keyDirty) ||
                !formik.values.confluence_key.match('^[a-zA-Z0-9]*$') ||
                (formik.values.confluence_key.length >
                  RESOURCE_KEY_MAX_LENGTH.confluence &&
                  keyDirty)
              }
              onChange={formik.handleChange}
              value={formik.values.confluence_key}
              onClick={onResourceKeyClick}
              fullWidth
              helperText={`Provide a key for your Confluence Space. Only letters and numbers allowed. No. of characters allowed Min=${RESOURCE_KEY_MIN_LENGTH.confluence}, Max=${RESOURCE_KEY_MAX_LENGTH.confluence}`}
            />

            <br />
            <br />
            <InputLabel
              htmlFor="confluence_name"
              style={{
                color:
                  (formik.values.confluence_name.length <
                    RESOURCE_NAME_MIN_LENGTH.confluence &&
                    nameDirty) ||
                  (formik.values.confluence_name.length >
                    RESOURCE_NAME_MAX_LENGTH.confluence &&
                    nameDirty)
                    ? 'red'
                    : 'black',
              }}
            >
              Confluence Space Name*
            </InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="confluence_name"
              name="confluence_name"
              required
              error={
                (formik.values.confluence_name.length <
                  RESOURCE_NAME_MIN_LENGTH.confluence &&
                  nameDirty) ||
                (!formik.values.confluence_name.match(RESOURCE_NAME_REGEX) &&
                  nameDirty) ||
                (formik.values.confluence_key.length >
                  RESOURCE_NAME_MAX_LENGTH.confluence &&
                  nameDirty)
              }
              onChange={formik.handleChange}
              value={formik.values.confluence_name}
              onClick={onResourceNameClick}
              fullWidth
              helperText={`Provide a name for your Confluence Space. Only letters, numbers and spaces allowed. No. of characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.confluence}, Max=${RESOURCE_NAME_MAX_LENGTH.confluence}. Spaces at start and end are not allowed.`}
            />

            <br />
            <br />
            <InputLabel htmlFor="confluence_description">
              Description
            </InputLabel>
            <TextField
              type="text"
              id="confluence_description"
              name="confluence_description"
              onChange={formik.handleChange}
              value={formik.values.confluence_description}
              minRows={4}
              multiline
              fullWidth
              error={
                formik.values.confluence_description.length >
                  RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
              }
              helperText={`Provide a brief description for your Confluence space. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
              placeholder="Example : To be used as a documentation repository to store all documentations related to the development within the One-click delivery automation solutions project."
              onClick={onDescriptionClick}
            />
            <br />
            <br />

            <Button
              type="submit"
              id="submit-confluence"
              disabled={
                formik.values.confluence_name.length <
                  RESOURCE_NAME_MIN_LENGTH.confluence ||
                formik.values.confluence_key.length <
                  RESOURCE_KEY_MIN_LENGTH.confluence ||
                formik.values.confluence_key.length >
                  RESOURCE_KEY_MAX_LENGTH.confluence ||
                !formik.values.confluence_key.match('^[a-zA-Z0-9]*$') ||
                !formik.values.confluence_name.match(RESOURCE_NAME_REGEX) ||
                formik.values.confluence_name.length >
                  RESOURCE_NAME_MAX_LENGTH.confluence ||
                formik.values.confluence_description.length >
                  RESOURCE_DESCRIPTION_MAX_LENGTH ||
                apiLoader
              }
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
