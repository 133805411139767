import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { RoundIcon } from '../RoundIcon';
import { Button } from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { NetPromoterScore } from '../NetPromoterScore';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { userJourneyTypes } from 'usg-types';

type Props = {
  userJourney: userJourneyTypes;
};
export const Footer = ({ userJourney }: Props) => {
  const classes = useStyles();
  const [dialogOpenNPS, setDialogOpenNPS] = React.useState(false);
  const dialogOnCloseHelper: () => void = () => {
    setDialogOpenNPS(false);
  };
  const handleOpenFeedback = () => {
    setDialogOpenNPS(true);
  };
  const handleOpenContactUs = () => {
    window.open('/docs/default/Component/stargate-welcome-guides/contact-us');
  };
  const handleOpenIdealPortal = () => {
    window.open('https://stargate.ideas.aha.io/');
  };

  return (
    <div className={classes.container}>
      <div className={classes.wraperDiv}>
        <div className={classes.card}>
          <div className="iconsection">
            <RoundIcon icon={<FeedbackIcon fontSize="small" />} />
          </div>
          <div>
            <div className="textsection">
              <div>
                <Typography
                  variant="subtitle2"
                  paragraph
                  className={classes.footerTitle}
                >
                  Have feedback for Stargate?
                </Typography>
                <Typography className={classes.footerText}>
                  Let us know what you think of Stargate. Your feedback helps us
                  improve Stargate to suit your needs.
                </Typography>
              </div>
              <div className="buttonsection">
                <Button
                  variant="contained"
                  type="button"
                  className={classes.feedbackBtn}
                  onClick={handleOpenFeedback}
                >
                  Provide Feedback
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.card}>
          <div className="iconsection">
            <RoundIcon icon={<ContactSupportIcon fontSize="small" />} />
          </div>

          <div className="textsection">
            <div>
              <Typography
                variant="subtitle2"
                paragraph
                className={classes.footerTitle}
              >
                Need support with Stargate?
              </Typography>
              <Typography className={classes.footerText}>
                Contact us and raise a ticket for the required <br /> support.
                We are happy to assist you.
              </Typography>
            </div>
            <div className="buttonsection">
              <Button
                variant="contained"
                type="button"
                className={classes.feedbackBtn}
                onClick={handleOpenContactUs}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.card}>
          <div className="iconsection">
            <RoundIcon icon={<EmojiObjectsIcon fontSize="small" />} />
          </div>
          <div className="textsection">
            <div>
              <Typography
                variant="subtitle2"
                paragraph
                className={classes.footerTitle}
              >
                Have an idea or Suggestion?
              </Typography>
              <Typography className={classes.footerText}>
                Drop in your ideas and suggestions on the Stargate Ideas Portal.
              </Typography>
            </div>
            <div className="buttonsection">
              <Button
                variant="contained"
                type="button"
                className={classes.feedbackBtn}
                onClick={handleOpenIdealPortal}
              >
                Ideas Portal
              </Button>
            </div>
          </div>
        </div>

        {dialogOpenNPS && (
          <NetPromoterScore
            userJourney={userJourney}
            npsLocation="footer"
            dialogOpen={dialogOpenNPS}
            dialogOnCloseHelper={dialogOnCloseHelper}
          />
        )}
      </div>
    </div>
  );
};
