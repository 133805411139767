import React, { useCallback, useEffect, useState } from 'react';
import { PageLayout } from '@internal/sg-ui-kit';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { useNavigate, useParams } from 'react-router';
import { rootRouteRef } from '../../routes';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import { projectApiRef } from '../../api';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import { isValidUrl } from 'sg-utils-frontend';
import { Progress } from '@backstage/core-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTime } from 'luxon';
import { Autocomplete } from '@material-ui/lab';
import {
  CONTRACT_NAME_MAX_LENGTH,
  CONTRACT_NAME_MIN_LENGTH,
  URL_CUE,
} from 'usg-types';
import { capitalize, isEmpty, isNil } from 'lodash';
import { useCompaniesApi } from '../../hooks/useCompaniesApi';
import { AlertModal } from '../AlertModal';
import { rootRouteRef as welcomeRouteRef } from '@internal/plugin-welcome-page';
import { usePermissions } from '@internal/plugin-projects';
import { useStyles } from './styles';

export const AssignUpdateProjectContract = ({}) => {
  const classes = useStyles();
  const rootLink = useRouteRef(rootRouteRef);
  const welcomePageRoute = useRouteRef(welcomeRouteRef);
  const navigate = useNavigate();
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const { projectId, contractId } = useParams() as {
    projectId: string;
    contractId: string;
  };
  const [projectData, setProjectData] = useState<any>();
  const [contractData, setContractData] = useState({} as any);
  const [companiesData, setCompaniesData] = useState([] as any);
  const [selectedCompanies, setSelectedCompanies] = useState([] as any);
  const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
  const [expiryDate, setExpiryDate] = useState<DateTime>(
    DateTime.now().plus({ years: 1 }),
  );
  const [apiLoader, setApiLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [cNameDirty, setCNameDirty] = useState(false);
  const [cTypeDirty, setCTypeDirty] = useState(false);
  const [linkDirty, setLinkDirty] = useState(false);
  const [companiesDirty, setCompaniesDirty] = useState(false);
  const [expiryDateError, setExpiryDateError] =
    useState<DateValidationError | null>(null);
  const {
    allCompanies,
    isSuccess: allCompaniesSuccess,
    message: allCompaniesMessage,
    isLoading: isLoadingCompanies,
  } = useCompaniesApi();
  const {
    isAdmin,
    isProjectOwner,
    isLoading: rolesCheckLoading,
  } = usePermissions();

  const backToTarget = projectId
    ? `/projects/${projectId}/contracts`
    : rootLink();
  const backToLink = {
    to: backToTarget,
    label: 'Back to Contracts',
  };

  const CONTRACT_TYPES = ['OWNERSHIP', 'SHARING'];

  const [{ loading, error }, fetchProject] = useAsyncFn(
    async (): Promise<any> => {
      const token = await authref.getIdToken();
      const params = {
        idToken: token,
        manipulators: ['participant_companies'],
      };

      const projectDataRes = await projectApi.getProjectByID(projectId, params);
      const code = projectDataRes?.response?.status;
      if (!isResponseStatus2XX(code)) {
        const errorMessage = projectDataRes?.response?.data?.message;
        errorApi.post(new Error(`${errorMessage}`));
      }
      setProjectData(projectDataRes?.response?.data);

      if (contractId) {
        setIsEditMode(true);
        const contractDataRes = await projectApi.getProjectLegalContractById(
          token,
          projectId,
          contractId,
          { include_assigned_companies: true },
        );
        contractDataRes.assigned_companies =
          contractDataRes?.assigned_companies?.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
        setSelectedCompanies(contractDataRes?.assigned_companies || []);
        setStartDate(DateTime.fromISO(contractDataRes.started_on));
        setExpiryDate(DateTime.fromISO(contractDataRes.ended_on));
        setContractData(contractDataRes);
      }
    },
  );

  const fetchCompanies = useCallback(() => {
    if (projectData && allCompanies.length !== 0) {
      const allCompanyIDNameMapping = allCompanies.reduce(
        (mapping: any, company: any) => {
          mapping[company.id] = {
            id: company.id,
            name: company.name,
          };
          return mapping;
        },
        {},
      );

      const companyData = projectData?.participant_companies.map(
        (company: any) => allCompanyIDNameMapping[company.company_id],
      );
      setCompaniesData(companyData);
    }
  }, [projectData, allCompanies]);

  const onSubmit = async (values: any) => {
    try {
      setApiLoader(true);
      const companyIds = selectedCompanies.map((company: any) => company.id);

      const token = await authref.getIdToken();
      const assignUpdateContractData = {
        name: values.contractName.trim(),
        contract_type: values.contractType,
        description: values.description,
        contract_url: values.documentLink,
        started_on: startDate?.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'") || '',
        ended_on: expiryDate?.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'") || '',
        assigned_company_ids: companyIds,
      };

      if (isEditMode) {
        await projectApi.updateProjectLegalContractById(
          token,
          projectId,
          contractId,
          assignUpdateContractData,
        );
      } else {
        await projectApi.createProjectLegalContract(
          token,
          projectId,
          assignUpdateContractData,
        );
      }
      setOpenDialog(true);
    } catch (err) {
      errorApi.post(err);
    }
    setApiLoader(false);
  };

  const cancelAssignUpdate = () => {
    setOpenDialog(false);
    navigate(`/projects/${projectId}/contracts`);
  };

  const checkFieldIsInvalid = (fieldName: string, currentValue: any) => {
    let isInValid = false;
    switch (fieldName) {
      case 'contractName':
        isInValid =
          cNameDirty &&
          (currentValue?.trim().length < CONTRACT_NAME_MIN_LENGTH ||
            currentValue?.trim().length > CONTRACT_NAME_MAX_LENGTH);
        break;
      case 'contractType':
        isInValid =
          cTypeDirty && (isNil(currentValue) || isEmpty(currentValue));
        break;
      case 'documentLink':
        isInValid =
          linkDirty &&
          (isNil(currentValue) ||
            isEmpty(currentValue) ||
            !isValidUrl(currentValue));
        break;
      case 'companies':
        isInValid = companiesDirty && currentValue.length === 0;
        break;
      default:
        break;
    }
    return isInValid;
  };

  const errorMessage = React.useMemo(() => {
    switch (expiryDateError) {
      case 'minDate': {
        return 'Please select a date that comes after the start date.';
      }
      case 'maxDate': {
        return 'Please select a date that is within range.';
      }
      case 'invalidDate': {
        return 'Your date is not valid';
      }
      default: {
        return '';
      }
    }
  }, [expiryDateError]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  useEffect(() => {
    if (!rolesCheckLoading) {
      (async () => {
        await fetchCompanies();
      })();
    }
  }, [fetchCompanies, rolesCheckLoading]);

  if (loading || isLoadingCompanies || rolesCheckLoading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(
      new Error(
        `Failed to load Project/Contract details in contracts due to ${error.message}.`,
      ),
    );
  }

  if (!rolesCheckLoading && !isAdmin && !isProjectOwner(Number(projectId))) {
    navigate(`/projects/${projectId}`);
  }

  if (!allCompaniesSuccess && !isLoadingCompanies) {
    return (
      <AlertModal
        alertMessage={allCompaniesMessage}
        isModalOpen={!allCompaniesSuccess}
        onClose={() => {
          navigate(welcomePageRoute());
        }}
      />
    );
  }

  return (
    <PageLayout
      type="entity"
      title={projectData?.name || null}
      headerAdditionalControls={
        <ProjectOwners owners={projectData?.owners || null} />
      }
      backToLink={backToLink}
    >
      {apiLoader ? <Progress /> : ''}
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              contractName: contractData?.name || '',
              contractType: contractData?.contract_type || '',
              documentLink: contractData?.contract_url || '',
              startDate: startDate,
              expiryDate: expiryDate,
              companies: selectedCompanies,
              description: contractData?.description || '',
            }}
            onSubmit={onSubmit}
          >
            {formik => (
              <Form>
                <InputLabel
                  htmlFor="contractName"
                  className={
                    checkFieldIsInvalid(
                      'contractName',
                      formik.values.contractName,
                    )
                      ? classes.invalidField
                      : ''
                  }
                >
                  Contract Name*
                </InputLabel>
                <TextField
                  type="text"
                  id="contractName"
                  name="contractName"
                  required
                  value={formik.values.contractName}
                  fullWidth
                  error={checkFieldIsInvalid(
                    'contractName',
                    formik.values.contractName,
                  )}
                  onChange={formik.handleChange}
                  onClick={() => setCNameDirty(true)}
                  helperText={`No. of Characters allowed Min=${CONTRACT_NAME_MIN_LENGTH}, Max=${CONTRACT_NAME_MAX_LENGTH}`}
                />

                <InputLabel
                  htmlFor="contractType"
                  className={
                    checkFieldIsInvalid(
                      'contractType',
                      formik.values.contractType,
                    )
                      ? classes.invalidField
                      : ''
                  }
                >
                  Type*
                </InputLabel>
                <Select
                  id="contractType"
                  name="contractType"
                  displayEmpty
                  onChange={formik.handleChange}
                  value={formik.values.contractType}
                  input={<OutlinedInput label="Tag" />}
                  fullWidth
                  error={checkFieldIsInvalid(
                    'contractType',
                    formik.values.contractType,
                  )}
                  onClick={() => setCTypeDirty(true)}
                >
                  {CONTRACT_TYPES.map(type => (
                    <MenuItem key={type} value={type}>
                      {capitalize(type)}
                    </MenuItem>
                  ))}
                </Select>

                <InputLabel
                  htmlFor="documentLink"
                  className={
                    checkFieldIsInvalid(
                      'documentLink',
                      formik.values.documentLink,
                    )
                      ? classes.invalidField
                      : ''
                  }
                >
                  Document Link*
                </InputLabel>
                <TextField
                  type="text"
                  id="documentLink"
                  name="documentLink"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.documentLink}
                  fullWidth
                  onClick={() => setLinkDirty(true)}
                  error={checkFieldIsInvalid(
                    'documentLink',
                    formik.values.documentLink,
                  )}
                  helperText={URL_CUE}
                />

                <InputLabel htmlFor="startDate">Start Date*</InputLabel>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    name="startDate"
                    className={classes.datePicker}
                    timezone="UTC"
                    value={startDate}
                    maxDate={expiryDate}
                    slotProps={{
                      field: {
                        readOnly: true,
                        id: 'startDate',
                      },
                      previousIconButton: {
                        id: 'startPreviousMonth',
                      },
                      nextIconButton: {
                        id: 'startNextMonth',
                      },
                      switchViewButton: {
                        id: 'startSwitchYearView',
                      },
                    }}
                    onChange={(value: any) => setStartDate(value)}
                  />
                </LocalizationProvider>

                <InputLabel htmlFor="expiryDate">Expiry Date*</InputLabel>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    name="expiryDate"
                    className={classes.datePicker}
                    value={expiryDate}
                    timezone="UTC"
                    minDate={startDate}
                    onError={newError => setExpiryDateError(newError)}
                    slotProps={{
                      textField: {
                        helperText: errorMessage,
                      },
                      field: {
                        readOnly: true,
                        id: 'expiryDate',
                      },
                      previousIconButton: {
                        id: 'expiryPreviousMonth',
                      },
                      nextIconButton: {
                        id: 'expiryNextMonth',
                      },
                      switchViewButton: {
                        id: 'expirySwitchYearView',
                      },
                    }}
                    onChange={(value: any) => setExpiryDate(value)}
                  />
                </LocalizationProvider>

                <Autocomplete
                  multiple
                  size="small"
                  value={selectedCompanies}
                  filterOptions={x => {
                    return x;
                  }}
                  onChange={(_: React.ChangeEvent<{}>, value: any) =>
                    setSelectedCompanies(value)
                  }
                  blurOnSelect
                  noOptionsText="No results found"
                  options={companiesData}
                  getOptionLabel={(o: any) => o.name}
                  getOptionSelected={(o: any, v: any) =>
                    o.name && v.name ? o.name === v.name : false
                  }
                  renderOption={(o: any) => o.name}
                  loading={loading}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Companies*"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      placeholder="Select one or more companies"
                      helperText="Select one or more companies"
                      onClick={() => setCompaniesDirty(true)}
                      error={checkFieldIsInvalid(
                        'companies',
                        selectedCompanies,
                      )}
                    />
                  )}
                />

                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  type="text"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  minRows={4}
                  multiline
                  fullWidth
                  onChange={formik.handleChange}
                />
                <Grid item xs={6} className={classes.buttons}>
                  <Button
                    type="reset"
                    id="assign-contract-cancel"
                    variant="outlined"
                    onClick={cancelAssignUpdate}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    id="assign-contract-submit"
                    variant="contained"
                    disabled={
                      formik.values.contractName?.trim().length <
                        CONTRACT_NAME_MIN_LENGTH ||
                      formik.values.contractName?.trim().length >
                        CONTRACT_NAME_MAX_LENGTH ||
                      formik.values.contractType === '' ||
                      formik.values.documentLink === '' ||
                      !isValidUrl(formik.values.documentLink) ||
                      !startDate ||
                      !expiryDate ||
                      (startDate && expiryDate && startDate > expiryDate) ||
                      selectedCompanies.length === 0 ||
                      expiryDateError !== null ||
                      apiLoader
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
          <Dialog open={openDialog} onClose={cancelAssignUpdate} maxWidth="xs">
            <DialogContent
              id="confirmation-dialog"
              style={{ fontWeight: 'bold' }}
            >
              Contract has been {isEditMode ? 'updated' : 'assigned'}{' '}
              sucessfully.
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                variant="contained"
                size="small"
                onClick={cancelAssignUpdate}
                id="confirmation-dialog-button"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
